.portfoliocard {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  overflow: hidden;

  .card-top {
    border-radius: 19.076px;
    background: #fff;
    box-shadow: 0px 0px 19.858px 0px rgba(255, 0, 0, 0.12);
    overflow: hidden;
    padding: 9% 16px 20px 16px;
    position: relative;

    width: 100%;
    height: 321px;
    .animated-img {
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      transform: translate(100%);
      transition: all 0.7s linear;
      object-fit: cover;
    }
    h2 {
      position: absolute;
      top: -9%;
      left: 0%;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: rgba(37, 0, 127, 0.4);
      color: rgba(255, 255, 255, 0.7);
      font-family: "Roboto";
      font-size: 4vw;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
      text-transform: uppercase;
      width: 100%;
      text-align: center;
    }
    .card-inner {
      position: relative;
      z-index: 2;
      height: 260px;
      border-radius: 12.343px;
      overflow: hidden;
      filter: drop-shadow(0px 0px 13.465px rgba(0, 0, 0, 0.18));
      .project-img {
        transition: all 0.4s linear;
        width: 100%;
        height: 100%;
        border-radius: 12.343px;
        backdrop-filter: blur(3.3663716316223145px);
        object-fit: cover;
      }
      .card-layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        backdrop-filter: blur(3.3663716316223145px);
        box-shadow: 0px 0px 13.465px 0px rgba(0, 0, 0, 0.18);
        border-radius: 12.343px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7%;
        transition: all 0.7s linear;
        opacity: 0;
        z-index: 3;

        button {
          position: relative;
          z-index: 3;
          transform: translateY(350%);
          transition: all 0.4s linear;
          border-radius: 20.29px;
          background: #6f00ff;
          padding: 1.7% 7%;
          color: var(--white);
          text-align: center;
          font-family: "Montserrat";
          font-size: 16.087px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%; /* 140% */
        }
      }
    }
  }

  h4 {
    color: var(--black);
    font-family: "Montserrat";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  &:hover {
    .card-top {
      .animated-img {
        transform: translate(00%);
      }
      .card-inner {
        .project-img {
          transform: scale(1.05);
        }
        .card-layer {
          opacity: 1;

          button {
            transform: translateY(0%);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .portfoliocard {
    row-gap: 10px;

    .card-top {
      height: 300px;
      padding: 9% 12px 20px 12px;
      h2 {
        top: -7%;
      }
      .card-inner {
        height: 255px;
        .card-layer {
          button {
            padding: 9px 7%;
            font-size: 15px;
          }
        }
      }
    }

    h4 {
      font-size: 24px;
    }
  }
}
@media screen and (max-width: 1280px) {
  .portfoliocard {
    row-gap: 10px;

    .card-top {
      height: 250px;
      padding: 30px 12px 20px 12px;
      h2 {
        top: -8%;
      }
      .card-inner {
        height: 210px;
        .card-layer {
          button {
            padding: 9px 7%;
            font-size: 15px;
          }
        }
      }
    }

    h4 {
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .portfoliocard {
    row-gap: 10px;

    .card-top {
      height: 220px;
      padding: 25px 10px 15px 10px;
      h2 {
        top: -6%;
      }
      .card-inner {
        height: 185px;
      }
    }

    h4 {
      font-size: 20px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .portfoliocard {
    row-gap: 10px;

    .card-top {
      height: 220px;
      padding: 25px 10px 15px 10px;
      h2 {
        top: -12px;
        font-size: 30px;
      }
      .card-inner {
        height: 185px;
      }
    }

    h4 {
      font-size: 20px;
    }
  }
}
