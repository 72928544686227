.join {
  padding: 152px;
  padding-bottom: 170px;
  gap: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h2 {
    color: #000;
    font-family: Roboto;
    font-size: 78.165px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
  }
  p {
    color: #000;
    text-align: justify;
    font-family: Roboto;
    font-size: 33.913px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    min-width: 347px;
    height: 93px;
    background: #000;
    color: #fff;
    border: 2px solid #000;
    border-radius: 8px;
    text-align: justify;
    font-family: "Roboto";
    font-size: 28.057px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition: all 0.4s linear;
    position: relative;
    z-index: 2;
    &:hover {
      transition: all 0.4s linear;
      color: #000;
      z-index: 4;
      img {
        display: none;
      }
      .handshake {
        display: unset;
        transition: all 0.4s linear;
      }
      .layer {
        height: 100%;
        transition: all 0.4s linear;
        z-index: -1;
        opacity: 1;
      }
    }
    img {
      position: relative;
      z-index: 2;
      transition: all 0.4s linear;
    }
    .handshake {
      display: none;
      transition: all 0.4s linear;
      width: 92px;
      height: 92px;
    }
    .layer {
      z-index: 1;
      transition: all 0.4s linear;
      background: #fff;
      border: 2px solid #fff;
      border-radius: 8px;
      width: 100%;
      height: 5px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
}

@media screen and (max-width: 1600px) {
  .join {
    padding: 50px 0;
    gap: 71px;
    .join button {
      width: 307px;
      height: 80px;
      font-size: 23.057px;
    }
  }
}
@media screen and (max-width: 1400px) {
  .join {
    gap: 20px;
    button {
      width: 300px;
      height: 70px;
      padding: 0 20px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .join {
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 23px;
    }
  }
}
@media screen and (max-width: 600px) {
  .join {
    padding: 25px 20px;
    gap: 10px;
    h2 {
      font-size: 25px;
    }
    p {
      font-size: 18px;
      text-align: center;
    }
    button {
      padding: 0px 10px;
      width: 200px;
      height: unset;
      font-size: 16px;

      img {
        width: 50px;
        height: 50px;
      }
      .handshake {
        display: none;
        width: 50px;
        height: 50px;
      }
    }
  }
}
