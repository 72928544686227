.about-sec {
    padding: 50px 100px;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    .content{
        display: grid; /* Turn the container into a grid */
        grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
        gap: 30px; /* Spacing between the columns */
        align-items: center; /* Center content vertically */

         .left{

             h2 {
                color: #6F00FF;
                font-family: Montserrat, sans-serif;
                font-size: 73.509px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                text-align: start;

                margin-bottom: 20px;
              }
              p{
                text-align: start;
              }
         }
         .right{
            width: 100%;
            width: fit-content;
            margin: 0 auto;
            img{
                width: 100%;

            }
         }
    }

  
    // Media Query for max-width 1400px
    @media screen and (max-width: 1400px) {
      padding: 40px 80px;

      .content{
        .left{
          h2 {
            font-size: 55px;
          }

        }
      }
    }
  
    // Media Query for max-width 1024px (tablets and small laptops)
    @media screen and (max-width: 1024px) {
      padding: 30px 40px;

        .content{
          grid-template-columns: repeat(1, 1fr); /* Two equal-width columns */
        .left{
            h2{
                font-size: 40px;
                width: fit-content;
                margin: 0 auto;
                margin-bottom: 16px;
                text-align: center;
            }
            p{
              text-align: center;
            }
        }
        .right{
            width: fit-content;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                width: 60%;
                margin: 0 auto;

            }
        }

      }
    }
  
    // Media Query for max-width 600px (mobile devices)
    @media screen and (max-width: 600px) {
      padding: 20px;
      .content{
        .left{
          h2 {
            font-size: 20px;
            margin-bottom: 8px;
          }
          p{
            font-size: 14px;
          }

        }
      }
    }
    
    .right {
        img{
          width: 100%
        }

    }
  }
  