.Contactinfo {
  padding: 120px 60px;
  row-gap: 100px;
  .content {
    max-width: 1560.698px;
    // height: 805.139px;
    border-radius: 12.071px;
    background: #fff;
    box-shadow: 0px 0px 72.426px 36.213px rgba(0, 0, 0, 0.03);
    padding: 12px;
    width: 100%;
    .left {
      width: 45%;
      padding: 0 40px;
      background: #f8f8f8;
      border-radius: 12.071px;
      background-image: url("../../assets/img/contact.webp");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      row-gap: 20px;
      h1 {
        color: #000;
        text-align: center;
        font-family: "Roboto";
        font-size: 70.676px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: uppercase;
        z-index: 2;
        span {
          border-bottom: 10px solid #000;
        }
      }
      label {
        z-index: 2;
        font-size: 30px;
        font-weight: normal;
        text-align: center;
      }
      .green-shadow {
        position: absolute;
        z-index: 1;
        width: 398px;
        height: 398px;
        border-radius: 398px;
        background: rgba(0, 205, 172, 0.8);
        filter: blur(112px);
      }
    }
    .right {
      width: 55%;
      padding: 50px;
      gap: 20px;

      .input-box-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 20px;
        column-gap: 58px;
      }

      .input-content {
        width: 100%;
        label {
          width: 50%;
          color: #000;
          font-size: 14.485px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          input {
            width: 100%;
            margin-top: 10px;
            height: 45px;
            border-radius: 5px;
            border: 1px solid #000;
            background: #fff;
          }
        }
      }
      .service {
        margin-top: 34px;
        .labels {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 15px;
          margin-top: 16px;
          label {
            display: flex;
            align-items: center;
            p {
              padding-left: 12px;
            }
          }
        }
      }
      .message {
        // margin-top: 34px;
        textarea {
          font-family: "Poppins";
          padding-top: 12px;
          padding-left: 14px;
          margin-top: 18px;
          width: 100%;
          height: 128px;
          border-radius: 5px;
          border: 1px solid #000;
          background: #fff;
          outline: none;
          color: var(--black);
          font-size: 16.899px;
          font-style: normal;
          line-height: 120%; /* 142.857% */
          &::placeholder {
            font-weight: 500;
            color: #8d8d8d;
          }
        }
        p {
          font-weight: 500;
          font-family: Poppins;
        }
      }
      .two-buttons {
        button {
          width: 193.332px;
          height: 48.785px;
          border-radius: 4.517px;
          border: 2px solid #000;
          box-shadow: 0px 0px 12.648px 0px rgba(0, 0, 0, 0.12);
          background: transparent;
          font-size: 14.455px;
          font-weight: 500;
        }
        label {
          cursor: pointer;
          width: 193.332px;
          height: 48.785px;
          border-radius: 4.517px;
          border: 2px solid #000;
          box-shadow: 0px 0px 12.648px 0px rgba(0, 0, 0, 0.12);
          background: transparent;
          font-size: 14.455px;
          font-weight: 500;
          font-weight: bold;
        }
        .rightbutton {
          background: #000;
          color: #fff;
        }
      }
    }
  }
  .locations {
    max-width: 1560.698px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 42px;
    user-select: none;

    .address {
      border-radius: 9.958px;
      background: var(--white);
      box-shadow: 0px 0px 21.909px 0px rgba(0, 0, 0, 0.09);
      display: flex;
      align-items: center;
      overflow: hidden;
      .left {
        background: var(--primary);
        width: 19.5%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .flag-img {
          border-radius: 100%;
          width: 150px;
          height: 150px;
          position: absolute;
          top: 50%;
          bottom: 50%;
          right: -45%;
          transform: translate(0, -50%);
          img {
            border-radius: 100%;
            height: 100%;
            width: 100%;
          }
        }
      }
      .right {
        width: 80.5%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 29px;
        padding: 50px 29px 50px 80px;
        .location {
          display: flex;
          flex-direction: column;
          row-gap: 5px;
          .large-para {
            color: var(--black);
            line-height: 100%;
            font-family: "Montserrat";
            font-weight: 600;
          }
          .large-paraDeci {
            text-align: left;
            font-weight: 600;
            color: #3e4343;
          }
          img {
            width: 24px;
            height: 24px;
            object-fit: cover;
          }
        }
        .map {
          overflow: hidden;
          width: 147px;
          height: 147px;
          border-radius: 8px;
          background: lightgray 50% / cover no-repeat;
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}

// Media Queries

@media screen and (max-width: 1570px) {
  .Contactinfo {
    padding-left: 50px;
    padding-right: 50px;
    .content {
      width: 100%;
      height: 100%;
      .left {
        width: 40%;
        h1 {
          font-size: 55px;
        }
      }
      .right {
        width: 60%;
      }
    }
    .locations {
      column-gap: 30px;

      .address {
        .left {
          .flag-img {
            width: 140px;
            height: 140px;
            right: -50%;
          }
        }
        .right {
          column-gap: 20px;
          padding: 40px 20px 40px 70px;
          .location {
            .large-paraDeci {
              font-size: 15px;
            }
          }
          .map {
            width: 120px;
            height: 120px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1350px) {
  .Contactinfo {
    padding-left: 30px;
    padding-right: 30px;
    .content {
      width: 100%;
      height: 100%;
      .left {
        width: 40%;
        padding: 0 20px;
        h1 {
          font-size: 40px;
          span {
            border-bottom: 5px solid #000;
          }
        }

        label {
          font-size: 16px;
        }
        .green-shadow {
          width: 250px;
          height: 250px;
        }
      }
      .right {
        width: 60%;
        padding: 20px;
        .input-box-wrapper {
          row-gap: 20px;
          column-gap: 30px;
        }
        .service {
          .labels {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
          }
        }
      }
    }
    .locations {
      column-gap: 20px;

      .address {
        .left {
          width: 17%;
          .flag-img {
            width: 100px;
            height: 100px;
            right: -40%;
          }
        }
        .right {
          width: 83%;
          column-gap: 10px;
          padding: 20px 10px 20px 40px;
          .location {
            .large-para {
              font-size: 18px;
            }
            .large-paraDeci {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .Contactinfo {
    .locations {
      column-gap: 20px;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 20px;
      .address {
        max-width: 550px;
        margin: 0px auto;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .Contactinfo {
    padding: 0px 30px 40px 30px;
    row-gap: 40px;
    .content {
      flex-direction: column;
      align-items: center;
      .left {
        padding: 20px;
        width: 100%;
        height: 250px;
        row-gap: 10px;
        h1 {
          font-size: 30px;
        }
        label {
          font-size: 20px;
          margin-top: 0px;
        }
        .green-shadow {
          width: 250px;
          height: 350px;
          border-radius: 100%;
        }
      }
      .right {
        width: 100%;
        padding: 10px;
        gap: 20px;

        .input-box-wrapper {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          row-gap: 20px;
          column-gap: 20px;
        }

        .input-content {
          width: 100%;
          label {
            width: 50%;
            line-height: 110%; /* 166.667% */
            input {
              width: 100%;
              margin-top: 0px;
              height: 40px;
              padding-left: 10px;
            }
          }
        }
        .service {
          margin-top: 10px;
          .labels {
            margin-top: 10px;
          }
        }
        .message {
          textarea {
            padding: 10px;
            margin-top: 10px;
            font-size: 15px;
            line-height: 110%; /* 142.857% */
          }
        }
        .two-buttons {
          button {
            width: 193.332px;
            height: 48.785px;
            border-radius: 4.517px;
            border: 2px solid #000;
            box-shadow: 0px 0px 12.648px 0px rgba(0, 0, 0, 0.12);
            background: transparent;
            font-size: 14.455px;
            font-weight: 500;
          }
          label {
            cursor: pointer;
            width: 193.332px;
            height: 48.785px;
            border-radius: 4.517px;
            border: 2px solid #000;
            box-shadow: 0px 0px 12.648px 0px rgba(0, 0, 0, 0.12);
            background: transparent;
            font-size: 14.455px;
            font-weight: 500;
            font-weight: bold;
          }
          .rightbutton {
            background: #000;
            color: #fff;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .Contactinfo {
    padding: 50px 10px;
    h2 {
      font-size: 27px;
    }
    row-gap: 20px;
    .content {
      flex-direction: column;
      padding: 10px;
      margin-top: 10px !important;
      .left {
        width: 100%;
        margin: 0;
        padding: 10px;
        height: 200px;
        h1 {
          font-size: 27px;
        }
        label {
          font-size: 18px;
        }
        .green-shadow {
          width: 150px;
          height: 150px;
        }
      }
      .right {
        width: 100%;
        padding: 10px;
        margin-top: 10px;
        .input-box-wrapper {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 20px !important;
        }
        .input-content {
          flex-direction: column;
          gap: 30px;
          label {
            width: 100%;
          }
        }
        .service {
          margin-top: 10px;
          .labels {
            grid-template-columns: repeat(1, 1fr);
          }
        }
        .two-buttons {
          column-gap: 20px;
          justify-content: center !important;
          label {
            width: unset;
            padding: 10px;
          }
          button {
            width: 140px;
          }
        }
      }
    }
    .locations {
      grid-template-columns: repeat(1, 1fr);

      .address {
        border-radius: 9px;
        flex-direction: column;
        padding: 20px;
        row-gap: 10px;
        max-width: 400px;
        .left {
          width: 100%;
          height: unset;
          background: unset;

          .flag-img {
            width: 100px;
            height: 100px;
            position: unset;
            transform: unset;
            img {
              border-radius: 100%;
              height: 100%;
              width: 100%;
            }
          }
        }
        .right {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          row-gap: 10px;
          padding: 0px;
          .location {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            row-gap: 5px;
            .large-para {
              font-size: 18px;
            }
            .large-paraDeci {
              font-size: 15px;
              text-align: center;
            }
            img {
              width: 24px;
              height: 24px;
              object-fit: cover;
            }
          }
          .map {
            overflow: hidden;
            width: 147px;
            height: 147px;
            border-radius: 8px;
            background: lightgray 50% / cover no-repeat;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
}
