.quote-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 33px;
  background: url(/assets/img/quoteback.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  max-width: 951px;
  margin: 82px auto 100px auto;
  padding: 134px 0px;
  .top {
    padding: 13px 0px 0px 0px;
    border-radius: 20.277px 0px 14.747px 1.106px;
    background: rgba(145, 148, 252, 0.2);
    .inner {
      border-radius: 20.277px 0px 14.747px 1.106px;
      background: rgba(170, 63, 255, 0.1);
      padding: 4px 44px 15px 44px;
      display: flex;
      flex-direction: column;
      row-gap: 9px;
      h3 {
        color: var(--black);
        font-family: "Montserrat";
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-align: justify;
      }
      h4 {
        color: var(--black);
        text-align: justify;
        font-family: "Montserrat";
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        text-align: center;
      }
    }
  }
  .large-para {
    color: #000;
    text-align: center;
    font-family: "Montserrat";
    font-weight: 500;
    line-height: 175%; /* 175% */
    max-width: 765px;
    margin: 0px auto;
  }
}

@media screen and (max-width: 1500px) {
  .quote-sec {
    row-gap: 20px;
    margin: 50px auto 70px auto;
    padding: 100px 0px;
    max-width: 600px;
    .top {
      padding: 10px 0px 0px 0px;
      .inner {
        padding: 4px 30px 10px 30px;
        row-gap: 5px;
        h3 {
          font-size: 25px;
        }
        h4 {
          font-size: 20px;
        }
      }
    }
    .large-para {
      color: #000;
      text-align: center;
      font-family: "Montserrat";
      font-weight: 500;
      line-height: 175%; /* 175% */
      max-width: 765px;
      margin: 0px auto;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .quote-sec {
    row-gap: 10px;
    max-width: 500px;
    margin: 40px auto 40px auto;
    padding: 50px 20px;
    .top {
      padding: 8px 0px 0px 0px;
      .inner {
        padding: 4px 44px 15px 44px;
        row-gap: 5px;
        h3 {
          font-size: 22px;
        }
        h4 {
          font-size: 18px;
        }
      }
    }
    .large-para {
      line-height: 140%; /* 175% */
      max-width: 400px;
    }
  }
}

@media screen and (max-width: 600px) {
  .quote-sec {
    row-gap: 3px;
    max-width: 340px;
    margin: 40px auto 0px auto;
    padding: 30px 20px 50px 0px;
    .top {
      padding: 8px 0px 0px 0px;
      border-radius: 20.277px 0px 14.747px 1.106px;
      .inner {
        padding: 4px 20px 10px 20px;
        row-gap: 3px;
        h3 {
          font-size: 18px;
        }
        h4 {
          font-size: 15px;
        }
      }
    }
    .large-para {
      padding-left: 10px;
      font-size: 15px;
      line-height: 130%; /* 175% */
      max-width: 280px;
    }
  }
}
