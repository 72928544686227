.faqs-sec {
  padding-top: 70px;
  background: var(--bodycolor);
  .inner {
    padding: 0px 100px 100px 100px;
    max-width: 1582px;
    margin: 0px auto;
    .faqs-accordion {
      .accordion {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        align-items: flex-start;
        gap: 27px;
      }
      .accordion-item {
        border-radius: 16.033px;
        background: var(--white);
      }

      .accordion-button {
        padding: 0px;
        border-radius: 16.033px;
        color: var(--black);
        font-family: "Montserrat";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 127.273%; /* 127.273% */
        column-gap: 10px;
      }

      .accordion-button:focus {
        border: none;
        box-shadow: none;
      }
      .accordion-item {
        border: none;
        padding: 40px 40px 20px 40px;
        display: flex;
        flex-direction: column;
        row-gap: 9px;
      }
      .accordion-button:not(.collapsed) {
        color: unset;
        background-color: var(--white);
        box-shadow: unset;
      }
      .accordion-button:not(.collapsed)::after {
        background-image: url(/assets/icons/open.svg);

        transform: var(--bs-accordion-btn-icon-transform);
      }
      .accordion-button::after {
        flex-shrink: 0;
        width: var(--bs-accordion-btn-icon-width);
        height: var(--bs-accordion-btn-icon-width);
        margin-left: auto;

        content: "";
        background-image: url(/assets/icons/close.svg);
        background-repeat: no-repeat;
        background-size: cover;
        width: 41px;
        height: 41px;
        background-position: center;
        transition: var(--bs-accordion-btn-icon-transition);
      }
      .accordion-body {
        padding: 0px;
        color: var(--gray);
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 166%; /* 166.667% */
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .faqs-sec {
    padding-top: 70px;
    .inner {
      padding: 0px 100px 100px 100px;

      .faqs-accordion {
        .accordion {
          gap: 25px;
        }

        .accordion-button {
          font-size: 18px;
        }
        .accordion-item {
          padding: 30px;
          row-gap: 15px;
        }

        .accordion-body {
          font-size: 17px;
          line-height: 140%; /* 166.667% */
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .faqs-sec {
    padding-top: 50px;
    .inner {
      padding: 0px 60px 60px 60px;
      .faqs-accordion {
        .accordion {
          gap: 20px;
        }

        .accordion-item {
          padding: 25px;
        }

        .accordion-body {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .faqs-sec {
    padding-top: 40px;
    .inner {
      padding: 0px 40px 40px 40px;
      .faqs-accordion {
        .accordion {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .faqs-sec {
    padding-top: 0;
    .inner {
      padding: 25px 20px 25px 20px;

      .head {
        padding: 10px 90px 10px 90px;
        border-radius: 10px;
        margin-bottom: 40px;
      }
      .faqs-accordion {
        .accordion {
          grid-template-columns: repeat(1, 1fr);
          gap: 20px;
        }
        .accordion-item {
          border-radius: 10px;
        }
        .accordion-button::after {
          width: 25px;
          height: 25px;
        }
        .accordion-button {
          border-radius: 10px;
          font-size: 16px;
        }

        .accordion-item {
          padding: 15px;
          row-gap: 10px;
        }

        .accordion-body {
          font-size: 15px;
          line-height: 120%; /* 166.667% */
        }
      }
    }
  }
}
