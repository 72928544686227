.secureSolutions-sec {
  padding: 0px 50px ;
  max-width: 1614px;
  margin: 0px auto;
  width: 100%;
  .heading {
    max-width: 1225px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .detail-para {
      text-align: center;
      line-height: 170%;
    }
  }

  .secureSolutions-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 35px;
    padding-top: 136px;

    .secure-card {
      border-radius: 21.81px;
      background: linear-gradient(180deg, #6f00ff 0%, #805cad 100%);
      position: relative;
      max-width: 400px;
      height: 428px;
      .outer {
        height: 100%;
        width: 100%;

        .img-div {
          position: absolute;
          top: -90px;
          left: 20px;
          right: 0px;
          transform: rotate(11deg);
          transition: all 0.4s linear;
          img {
            width: 100%;
            max-height: 400px;
            object-fit: contain;
          }
        }
        .secure-card-content {
          padding: 60px 30px;
          height: 100%;
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          margin-top: auto;
          justify-content: flex-end;
          position: relative;
          z-index: 10;
        }
      }

      &:hover {
        .img-div {
          transform: rotate(-11deg);
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .secureSolutions-sec {

    .secureSolutions-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0px auto;
      max-width: 840px;
      gap: 100px 20px;
      .secure-card {
        height: 360px;
        width: 360px;
        .outer {
          .img-div {
            top: -80px;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            img {
              max-height: auto;
              max-width: 250px;
              margin: 0px auto;
            }
          }
          .secure-card-content {
            padding: 40px 20px;
            row-gap: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .secureSolutions-sec {
    padding: 0px 40px;
    .secureSolutions-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0px auto;
      max-width: 840px;
      gap: 100px 20px;
      .secure-card {
        height: 360px;
        width: 360px;
        .outer {
          .img-div {
            top: -80px;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            img {
              max-height: auto;
              max-width: 250px;
              margin: 0px auto;
            }
          }
          .secure-card-content {
            padding: 40px 20px;
            row-gap: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .secureSolutions-sec {
    padding: 0px 30px ;

    .heading {
      .detail-para {
        line-height: 120% !important;
      }
    }
    .secureSolutions-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0px auto;
      max-width: 840px;
      gap: 100px 20px;
      .secure-card {
        height: 380px;
        width: 300px;
        .outer {
          .img-div {
            top: -80px;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            img {
              max-height: auto;
              max-width: 240px;
              margin: 0px auto;
            }
          }
          .secure-card-content {
            padding: 40px 20px;
            row-gap: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .secureSolutions-sec {
    padding: 20px;
    .secureSolutions-cards {
      gap: 60px 20px;
      padding-top: 70px;
      .secure-card {
        height: 380px;
        width: 300px;
        .outer {
          .img-div {
            top: -40px;
            img {
              max-width: 200px;
            }
          }
          .secure-card-content {
            padding: 40px 20px;
            row-gap: 15px;
          }
        }
      }
    }
  }
}
