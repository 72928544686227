.helpinghands-sec {
  width: 100vw;
  height: unset;
  position: relative;
  // .helping-back {
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  //   object-fit: contain;
  //   width: 100%;
  // }
  .helping-back {
    width: 100%;
  }
  .helping-div {
    padding: 45px 34px;
    right: 10%;
    position: absolute;
    top: -76px;
    background: var(--primary);
    border-radius: 20px;
    border: 8px solid #fff;
    background: linear-gradient(98deg, #6f00ff 1.07%, #25007f 97.51%);
    h2 {
      color: var(--white);
      font-family: "Montserrat";
      font-size: 54.431px;
      font-style: normal;
      font-weight: 700;
      line-height: 51.677px; /* 94.94% */
    }
  }
}

@media screen and (max-width: 1536px) {
  .helpinghands-sec {
    .helping-div {
      padding: 25px 20px;
      top: -45px;
      h2 {
        font-size: 30px;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .helpinghands-sec {
    .helping-div {
      padding: 10px 10px;
      top: -35px;
      h2 {
        font-size: 15px;
        line-height: 40px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .helpinghands-sec {
    margin-top: 30px;
    height: 20vh;
    .helping-back {
      height: 200px;
      object-fit: cover;
    }
    .helping-div {
      padding: 5px 10px;
      right: 20px;
      top: -30px;
      border: 2px solid #fff;
      border-radius: 10px;
      h2 {
        font-size: 12px;
        line-height: 30px;
      }
    }
  }
}
