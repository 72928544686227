.slider2 {
  padding: 0px 100px 0 150px;
  height: 100vh;
  align-items: center;
  position: relative;
  .left {
    width: 50%;
    .later {
      .head-outer {
        width: fit-content;
        border-radius: 55.552px;
        background: #6f00ff;
        padding: 11px 9px;
        position: absolute;
        top: 73px;
        .head-inner {
          border-radius: 55.552px;
          background: rgba(145, 148, 252, 0.6);
          padding: 3px 80px;
          width: 100%;
          color: var(--white);
          font-family: "Roboto";
          font-size: 70px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          text-transform: uppercase;
        }
      }
    }
    h1 {
      font-family: "Roboto";
      font-size: 115px;
      font-style: normal;
      font-weight: 800;
      line-height: 81.105%;
      text-transform: uppercase;
      position: relative;
      .hash {
        font-size: 100px;
        margin-left: 10px;
      }
      .number {
        position: relative;
        z-index: 3;
        .filled {
          position: relative;
          z-index: 2;
        }
        .stroke {
          color: transparent;
          -webkit-text-stroke: 1px #fff;
          position: absolute;
          left: 7px;
          top: -5px;
          z-index: 1;
        }
      }
    }
    p {
      width: 80%;
      font-family: "Montserrat";
      line-height: 120%;
      text-align: justify;
    }
    .layer {
      position: absolute;
      bottom: -40%;
      right: 38%;
      transform: translateX(-50%);
      width: 230px;
      height: 230px;
      background: var(--primary);
      mix-blend-mode: multiply;
    }
  }
  .right {
    width: 50%;
  }
}

.featuredWork {
  // background-image: url("/assets/img/aiprojectsback.png");
  // background-size: cover;
  // background-position: "center";
  // background-repeat: "no-repeat";
  background: black;
  .swiper-button-prev {
    width: 66px;
    height: 66px;
    z-index: 99;
    opacity: 1;
    background: none;
    left: 50px !important;
    background-image: url("/assets/icons/leftswiper.svg") !important;
    background-size: cover;
    &::after {
      content: unset;
    }
  }
  .swiper-button-next {
    width: 66px;
    height: 66px;
    z-index: 99;
    opacity: 1;
    background: none;
    right: 50px !important;
    background-size: cover;
    background-image: url("/assets/icons/rightswiper.svg") !important;

    &::after {
      content: unset;
    }
  }
}

.feature-work-wrapper {
  width: 100vw;
  height: 100vh;
  .feature-work {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1600px) {
  .slider2 {
    .left {
      .later {
        .head-outer {
          top: 30px;
          .head-inner {
            padding: 3px 60px;
            font-size: 4vw;
          }
        }
      }
      h1 {
        font-size: 95px;
        .layer {
          width: 160px;
          height: 160px;
        }
      }
    }
  }
  .featuredWork {
    .swiper-button-prev {
      width: 40px;
      height: 40px;

      left: 40px !important;
    }
    .swiper-button-next {
      width: 40px;
      height: 40px;
      right: 40px !important;
    }
  }
}
@media screen and (max-width: 1400px) {
  .slider2 {
    .left {
      h1 {
        font-size: 75px;
        .layer {
          width: 160px;
          height: 160px;
        }
        .hash {
          font-size: 75px;
        }
      }
    }
  }
}
@media screen and (max-width: 1250px) {
  .slider2 {
    padding: 0 120px;
    .left {
      h1 {
        font-size: 60px;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .slider2 {
    padding: 50px 50px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    .left {
      max-width: 600px;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      .later {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        .head-outer {
          padding: 8px 6px;
          position: unset;
          top: unset;
          .head-inner {
            padding: 3px 40px;
            font-size: 4vw;
          }
        }
      }

      h2 {
        font-size: 30px;
      }
      h1 {
        .layer {
          display: none;
        }
        .hash {
          font-size: 7vw;
        }
      }

      p {
        width: 100%;
      }
    }
    .right {
      width: 400px;
    }
  }
  .featuredWork {
    height: 85vh !important;
    .swiper-button-prev {
      width: 30px;
      height: 30px;
      top: 60%;

      left: 20px !important;
    }
    .swiper-button-next {
      top: 60%;
      width: 30px;
      height: 30px;
      right: 20px !important;
    }
  }
}
@media screen and (max-width: 1024px) {
  .slider2 {
    padding: 50px 40px;
    gap: 30px;
    height: 80vh;
    .left {
      row-gap: 10px;
      .later {
        .head-outer {
          padding: 5px 4px;
          .head-inner {
            padding: 3px 30px;
            font-size: 25px;
          }
        }
      }

      h2 {
        font-size: 30px;
      }
      h1 {
        font-size: 35px;
        .hash {
          font-size: 20px;
        }
      }
    }
    .right {
      width: 100%;
      max-width: 400px;
    }
  }
  .featuredWork {
    height: 80vh !important;
    .swiper-button-prev,
    .swiper-button-next {
      top: 90%;
      width: 40px;
      height: 40px;
    }
  }
  .featuredWork {
    .swiper-button-prev {
      left: 40% !important;
    }
  }
  .featuredWork {
    .swiper-button-next {
      right: 40% !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .slider2 {
    padding: 40px 20px;
    gap: 20px;
    height: auto;
    .left {
      row-gap: 30px;
      p {
        text-align: left;
        font-size: 18px;
      }
      .later {
        row-gap: 30px;
      }
    }
  }
  .featuredWork {
    height: 100vh !important;

    .swiper-button-prev {
      left: 35% !important;
      top: 92%;
    }
  }
  .featuredWork {
    .swiper-button-next {
      right: 35% !important;
      top: 92%;
    }
  }
}
