/* Default Styles */
.heading-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: fit-content;
  margin: 0 auto;
}

.outer-head {
  font-family: 'Montserrat', sans-serif;
  font-size: 163.518px;
  font-weight: 700;
  width: fit-content;
  margin: 0 auto;
  background-image: linear-gradient(180deg, #6f00ff 30%, white 80%); 
  -webkit-background-clip: text;
  background-clip: text;   
  color: transparent;
  text-transform: uppercase;
  text-shadow: 4px 2px 6px rgba(255, 255, 255, 0.5);
  text-align: center;
}

.inner-head {
  color: #6F00FF;
  font-family: 'Montserrat', sans-serif;
  font-size: 110.107px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  width: fit-content;
  margin: 0 auto;
  margin-top: -90px;
}

.para {
  color: #6F00FF;
  font-family: Montserrat;
  font-size: 25.546px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-top: -10px;
}

@media screen and (max-width: 1400px) {
  .outer-head{
    font-size: 170.518px;

  }
  
  

  .inner-head{
     font-size: 70px;
     margin-top: -100px;
  }  
  
}
@media screen and (max-width: 1220px) {
  .outer-head{
    font-size: 100px;

  }
  .inner-head {
    margin-top: -57px;
}
  
  

 
  
}

@media screen and (max-width: 1024px) {
  .outer-head{
    font-size: 100px;

  }

  .inner-head{
     font-size: 50px;
     margin-top: -60px;
  }  
  

}

@media screen and (max-width: 768px) {

  .outer-head{
    font-size: 48.518px;

  }

  .inner-head{
     font-size: 20px;
     margin-top: -18px;
  } 
  .para{
    font-size: 12px;
    margin-top: 1px;
  }
  
}
@media screen and (max-width: 400px){
  .outer-head{
    font-size: 38.518px;

  }
  
}



