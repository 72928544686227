.iot_services-sec {
  // padding: 60px  75px;
  overflow: hidden;
  position: relative;

  .bg-left1 {
    position: absolute;
    top: 12%;
    left: 0;
    bottom: 0;
    z-index: 100;
    margin-left: -12%;
    margin-bottom: 1%;
    width: 402px;
    height: 360px;
  }

  .bg-left2 {
    position: absolute;
    top: 68%;
    left: 0%;
    bottom: 0;
    z-index: 100;
    margin-left: -12%;
    margin-bottom: 1%;
    width: 402px;
    height: 360px;
  }

  .bg-right1 {
    position: absolute;
    top: 35%;
    right: -10%;
    bottom: 0;
    z-index: 100;
    width: 402px;
    height: 360px;
  }

  .bg-right2 {
    position: absolute;
    top: 94%;
    right: 0%;
    bottom: 0;
    z-index: 100;
    margin-bottom: 1%;
    margin-right: -10%;
    width: 402px;
    height: 360px;
  }

  .iot-services {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    margin-top: 5rem;

    .services {
      margin-top: 5rem;
      margin-bottom: 5rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .service-1,
      .service-3,
      .service-5,
      .service-7 {
        display: flex;
        gap: 56px;
        position: relative;
        margin: 20px 0;

        .left {
          flex: 6;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .heading {
            h1 {
              font-size: 48px;
              text-align: left;
              text-transform: capitalize;

              .heading2 {
                color: #6f00ff;
                margin-left: 10px;
                text-transform: capitalize;
              }
            }
          }

          .desc {
            margin-top: 27px;
            position: relative;

            .step-img {
              position: absolute;
              left: 0;
              margin-left: -12%;
            }

            .para {
              font-size: 20px;
              font-weight: 500;
              color: #2b2b2b;
              text-align: left;
              line-height: 35px;
              text-transform: none;
            }

            .benefits {
              .benefit {
                display: flex;
                column-gap: 12px;
                margin: 24px 0;

                img {
                  width: 33px;
                  height: 33px;
                }

                p {
                  font-weight: 500;
                  font-size: 20px;
                  color: #2b2b2b;
                }
              }
            }
          }
        }

        .right {
          flex: 4;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .img-outer {
            max-width: 100%;
            width: 100%;
            padding: 21px;
            border-radius: 55.785px;
            box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.09);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            .inner-img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              /* Ensures images fit within the container */
            }

            img {
              width: 100%;
              border-radius: 52px;
              position: relative;
              z-index: 2;
            }

            .fever-back {
              position: absolute;
              left: 0;
              z-index: 1;
              bottom: 0;
              animation: 6s infinite ease-in-out;
            }

            @keyframes imgrotate {
              0% {
                transform: rotate(0);
              }

              // 100% {
              //   transform: rotate(360deg);
              // }
            }
          }
        }
      }

      .service-2,
      .service-4,
      .service-6,
      .service-8 {
        display: flex;
        gap: 56px;
        position: relative;
        margin: 20px 0;

        .left {
          flex: 6;
          display: flex;
          flex-direction: column;
          // align-items: center;
          justify-content: center;

          .heading {
            h1 {
              font-size: 48px;
              text-align: left;
              text-transform: capitalize;

              .heading2 {
                color: #6f00ff;
                margin-left: 10px;
                text-transform: capitalize;
              }
            }
          }

          .desc {
            margin-top: 27px;
            position: relative;
            width: 90%;

            .step-img {
              position: absolute;
              right: 0;
              top: -8%;
              margin-right: -10%;
              width: 220px;
            }

            .para {
              font-size: 20px;
              font-weight: 500;
              color: #2b2b2b;
              text-align: left;
              line-height: 35px;
              text-transform: none;
            }

            .benefits {
              .benefit {
                display: flex;
                column-gap: 12px;
                margin: 24px 0;

                img {
                  width: 33px;
                  height: 33px;
                }

                p {
                  font-weight: 500;
                  font-size: 20px;
                  color: #2b2b2b;
                }
              }
            }
          }
        }

        .right {
          flex: 4;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 20px auto;

          .img-outer {
            max-width: 100%;
            width: 100%;
            padding: 21px;
            border-radius: 55.785px;
            box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.09);
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .inner-img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            img {
              width: 100%;
              border-radius: 52px;
              position: relative;
              z-index: 2;
            }

            .fever-back {
              position: absolute;
              left: 0;
              z-index: 1;
              bottom: 0;
              animation: 6s infinite ease-in-out;
            }

            @keyframes imgrotate {
              0% {
                transform: rotate(0);
              }

              // 100% {
              //   transform: rotate(360deg);
              // }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1680px) {
  .iot_services-sec {
    .bg-left1 {
      margin-left: -18%;
      top: 13%;
    }

    .bg-left2 {
      top: 70%;
      margin-left: -18%;
    }

    .bg-right1 {
      margin-right: -14%;
      margin-top: 8%;
    }

    .bg-right2 {
      margin-right: -16%;
      top: 93%;
    }

    .iot-services {
      max-width: 1280px;
    }
  }
}

@media screen and (max-width: 1441px) {
  .iot_services-sec {
    .bg-left1 {
      margin-left: -21%;
      top: 13%;
    }

    .bg-left2 {
      top: 70%;
      margin-left: -21%;
    }

    .bg-right1 {
      margin-right: -18%;
      margin-top: 8%;
    }

    .bg-right2 {
      margin-right: -20%;
      top: 95%;
    }

    .iot-services {
      max-width: 1024px;

      .services {

        .service-1,
        .service-2,
        .service-3,
        .service-4,
        .service-5,
        .service-6,
        .service-7,
        .service-8 {
          margin: 14px 0;

          .left {
            .heading {
              h1 {
                font-size: 32px;
              }
            }

            .desc {
              margin-top: 22px;

              .para {
                line-height: 24px;
                font-size: 18px;
              }

              .benefits {
                .benefit {
                  margin: 20px 0;

                  p {
                    line-height: 24px;
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1281px) {
  .iot_services-sec {
    .bg-left1 {
      margin-left: -16%;
      top: 13%;
      width: 300px;
    }

    .bg-left2 {
      top: 72%;
      margin-left: -16%;
      width: 300px;

    }

    .bg-right1 {
      margin-right: -18%;
      margin-top: 8%;
      width: 300px;

    }

    .bg-right2 {
      margin-right: -18%;
      top: 95%;
      width: 300px;

    }

    .iot-services {
      max-width: 100%;
      margin: 0;
      margin-top: 5rem;
      padding-inline: 5rem;
    }
  }
}

@media screen and (max-width: 1025px) {
  .iot_services-sec {
    .bg-left1 {
      margin-left: -16%;
      top: 13%;
      width: 300px;
    }

    .bg-left2 {
      top: 72%;
      margin-left: -16%;
      width: 300px;

    }

    .bg-right1 {
      margin-right: -18%;
      margin-top: 8%;
      width: 300px;

    }

    .bg-right2 {
      margin-right: -18%;
      top: 95%;
      width: 300px;

    }

    .iot-services {

      .service-1,
      .service-3,
      .service-5,
      .service-7 {
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .left {
          .step-img {
            width: 200px;

          }

        }

        .right {
          width: 60%;
        }
      }

      .service-2,
      .service-4,
      .service-6,
      .service-8 {
        flex-direction: column-reverse;
        align-items: center;

        .step-img {
          width: 200px;

        }

        .right {
          width: 60%;
        }
      }
    }

  }
}


@media screen and (max-width: 771px) {
  .iot_services-sec {
    .bg-left1 {
      margin-left: -18%;
      top: 11%;
      width: 220px;

    }

    .bg-left2 {
      top: 72%;
      margin-left: -18%;
      width: 220px;

    }

    .bg-right1 {
      margin-right: -14%;
      margin-top: 8%;
      width: 220px;
      top: 36%;


    }

    .bg-right2 {
      margin-right: -15%;
      width: 220px;
      top: 96%;
    }

    .iot-services {
      max-width: 100%;
      margin: 0;
      margin-top: 3rem;
      padding-inline: 3rem;

      .services {
        margin-top: 3rem;
        margin-bottom: 3rem;

        .service-1,
        .service-3,
        .service-5,
        .service-7 {
          flex-direction: column;
          align-items: center;



        }

        .service-2,
        .service-4,
        .service-6,
        .service-8 {
          flex-direction: column-reverse;
          align-items: center;
          .left{
            .desc{
              .step-img {
                width: 170px;
                margin-top: 40px;
              }
            }
            
          }
        }

        .service-1,
        .service-2,
        .service-3,
        .service-4,
        .service-5,
        .service-6,
        .service-7,
        .service-8 {
          gap: 20px;

          .left {
            width: 100%;



            .heading {
              h1 {
                text-align: center;
                font-size: 28px;
              }
            }

            .desc {
              .step-img {
                width: 170px;
              }
              .para {
                font-size: 16px;
              }

              .benefits {
                .benefit {
                  p {
                    font-size: 16px;
                  }
                }
              }
            }
          }

          .right {
            width: 60%;

            .img-outer {
              padding: 15px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 641px) {
  .iot_services-sec {
    .bg-left1 {
      margin-left: -24%;
      top: 11%;

      width: 220px;
    }

    .bg-left2 {
      top: 72%;
      margin-left: -24%;
      width: 220px;

    }

    .bg-right1 {
      margin-right: -16%;
      margin-top: 8%;
      width: 220px;
      top: 36%;


    }

    .bg-right2 {
      margin-right: -18%;
      width: 220px;
      top: 96%;
    }

    .iot-services {
      max-width: 100%;
      margin: 0;
      margin-top: 3rem;
      padding-inline: 3rem;

      .services {
        margin-top: 3rem;
        margin-bottom: 3rem;

        .service-1,
        .service-3,
        .service-5,
        .service-7 {
          flex-direction: column;
          align-items: center;

        }

        .service-2,
        .service-4,
        .service-6,
        .service-8 {
          flex-direction: column-reverse;
          align-items: center;

          .left{
            .desc{
              .step-img{
                width: 150px;
                margin-top: 40px;
              }
            }
          }


        }

        .service-1,
        .service-2,
        .service-3,
        .service-4,
        .service-5,
        .service-6,
        .service-7,
        .service-8 {
          gap: 20px;

          .left {
            width: 100%;

            .heading {
              h1 {
                text-align: center;
                font-size: 28px;
              }
            }

            .desc {
              .step-img {
                width: 150px;
              }

              .para {
                font-size: 16px;
              }

              .benefits {
                .benefit {
                  p {
                    font-size: 16px;
                  }
                }
              }
            }
          }

          .right {
            width: 70%;

            .img-outer {
              padding: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 481px) {
  .iot_services-sec {
    .bg-left1 {
      margin-left: -22%;
      top: 13%;
      width: 120px;
      height: 160px;
    }

    .bg-left2 {
      top: 74%;
      margin-left: -23%;
      width: 160px;
      height: 200px;
    }

    .bg-right1 {
      margin-right: -18%;
      margin-top: 8%;
      width: 160px;
      height: 200px;
    }

    .bg-right2 {
      margin-right: -18%;
      top: 97%;
      width: 160px;
      height: 200px;
    }

    .iot-services {
      margin-top: 1.5rem;
      padding-inline: 1.5rem;

      .services {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        .service-1,
        .service-2,
        .service-3,
        .service-4,
        .service-5,
        .service-6,
        .service-7,
        .service-8 {
          gap: 10px;

          .left {
            .heading {
              h1 {
                font-size: 26px;
              }
            }
          }

          .right {
            width: 90%;

            .img-outer {
              padding: 5px;
            }
          }
        }
      }
    }
  }
}