.day-at-octaloop {
  padding: 100px 150px;

  .carousel-control-prev {
    opacity: 1;
    left: -180px;
    .carousel-control-prev-icon {
      background-image: url("../../assets/icons/arrow-left.svg");
      width: 90px;
      height: 90px;
    }
  }
  .carousel-control-next {
    opacity: 1;
    right: -180px;
    .carousel-control-next-icon {
      background-image: url("../../assets/icons/arrow-right.svg");
      width: 90px;
      height: 90px;
    }
  }

  .team-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    .team-img-wrapper {
      position: relative;
      overflow: hidden;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(111, 0, 255, 0.2);
        transition: all 0.3s linear;
        opacity: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s linear;
      }
      &:hover {
        img {
          transform: scale(1.3);
        }
        &::after {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .day-at-octaloop {
    padding: 80px 120px;

    .carousel-control-prev {
      left: -140px;
      .carousel-control-prev-icon {
        width: 60px;
        height: 60px;
      }
    }
    .carousel-control-next {
      right: -140px;
      .carousel-control-next-icon {
        width: 60px;
        height: 60px;
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  .day-at-octaloop {
    padding: 50px 40px;
    p {
      font-size: 16px !important;
    }
  }
}
@media screen and (max-width: 700px) {
  .day-at-octaloop {
    padding: 25px 10px;
    h3 {
      font-size: 25px !important;
      margin-bottom: 10px !important;
    }
    p {
      margin-bottom: 20px !important;
    }
    .carousel-control-prev {
      left: -140px;
      .carousel-control-prev-icon {
        width: 60px;
        height: 60px;
      }
    }
    .carousel-control-next {
      right: -140px;
      .carousel-control-next-icon {
        width: 60px;
        height: 60px;
      }
    }
  }
}
