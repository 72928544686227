.vision {
  padding: 100px 170px;
  .content {
    display: flex;
    align-items: center;
    gap: 100px;
    .left-content {
      max-width: 50%;
      h3 {
        text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.25);
        margin-bottom: 50px;
        span {
          text-shadow: none;
          border-radius: 39px;
          background: #c39ef9;
          border: 7px solid #e1ccff;
          padding: 0 10px 0 0;
        }
      }
    }
    .right-content {
      display: flex;
      justify-content: end;
      width: 50%;
    }
  }
}
@media screen and (max-width: 1700px) {
  .vision {
    padding: 80px 120px;
    .content {
      .left-content h3 {
        margin-bottom: 40px;
        font-size: 40px;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .vision {
    padding: 60px 70px;
    .content {
      .left-content h3 {
        margin-bottom: 35px;
        font-size: 35px;
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  .vision {
    padding: 60px 70px;
    .content {
      .left-content {
        max-width: 100%;
        h3 {
          margin-bottom: 20px;
          font-size: 25px;
        }
        p {
          font-size: 16px;
        }
      }
      .right-content {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .vision {
    padding: 0px 40px;
    .content {
      .left-content {
        max-width: 100%;
      }
      .right-content {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .vision {
    padding: 0px 20px;
    .content {
      .left-content {
        max-width: 100%;
        h3 {
          margin-bottom: 20px;
          font-size: 18px;
          text-align: center;
        }
        div {
          gap: 10px !important;
        }
        p {
          text-align: center;
        }
      }
      .right-content {
        display: none;
      }
    }
  }
}
