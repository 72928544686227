.animatedheading-sec {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    color: rgba(216, 217, 255, 0.4);
    font-family: "Montserrat";
    font-size: 7.8vw;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    text-align: center;
  }
  .top-img {
    position: absolute;
    top: 0;
    animation: topmove 6s infinite ease-in-out;
  }
  @keyframes topmove {
    0% {
      transform: translateX(-200px);
      width: 175px;
    }
    50% {
      transform: translateX(200px);

      width: 90px;
    }
    100% {
      transform: translateX(-200px);

      width: 175px;
    }
  }
  @keyframes bottommove {
    0% {
      transform: translateX(200px);
      width: 175px;
    }
    50% {
      transform: translateX(-200px);

      width: 90px;
    }
    100% {
      transform: translateX(200px);

      width: 175px;
    }
  }
  .bottom-img {
    position: absolute;
    bottom: 0;
    animation: bottommove 6s infinite ease-in-out;
  }
  .main-head {
    position: absolute;
    display: flex;
    align-items: center;
    h3 {
      color: var(--black);
      text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.25);
      font-family: "Montserrat";
      font-size: 2.7vw;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    div {
      border-radius: 39px;
      background: rgba(111, 0, 255, 0.2);
      mix-blend-mode: multiply;
      backdrop-filter: blur(40px);
      padding: 10px;
      .shadow-title {
        border-radius: 39px;
        background: rgba(111, 0, 255, 0.01);
        mix-blend-mode: multiply;
        backdrop-filter: blur(40px);
        padding: 0px 10px;
        color: #25007f;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .animatedheading-sec {
    h1 {
      font-size: 35px;
    }
    .top-img {
      display: none;
    }

    .bottom-img {
      display: none;
    }
    .main-head {
      position: absolute;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      h3 {
        font-size: 18px;
      }
      div {
        padding: 6px;
        .shadow-title {
          padding: 0px 10px;
        }
      }
    }
  }
}
