.hiring {
  padding: 100px 170px;
}

@media screen and (max-width: 1700px) {
  .hiring {
    padding: 80px 120px;
  }
}
@media screen and (max-width: 1024px) {
  .hiring {
    padding: 0px 40px 50px 40px;
    p {
      padding: 20px 0px !important;
    }
  }
}
@media screen and (max-width: 700px) {
  .hiring {
    padding: 30px 20px;
    h3 {
      font-size: 25px;
      // margin-bottom: 10px !important;
    }
    p {
      padding: 15px 0px !important;
    }
  }
}
