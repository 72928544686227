.input-wrapper {
  // .input-content {
  //     gap: 58px;
  //     width: 100%;
  display: flex;
  flex-direction: column;
  label {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
  }
  input {
    border-radius: 5px;
    border: 1px solid #000;
    background: #fff;
    padding: 14px;
    font-size: 15px;
  }
}

@media screen and (max-width: 1280px) {
  .input-wrapper {
    input {
      padding: 10px;
    }
  }
}
