* {
  font-family: "Montserrat", "sans-serif";
}
a {
  cursor: pointer;
}
html {
  scroll-behavior: smooth;
}

// ---------------custom margins

.mt-30 {
  margin-top: 30px;
}

// ---------------custom image sizes
.img-100 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-btn {
  background: var(--primary);
  padding: 18px 45px;
  color: var(--white);

  text-align: center;
  font-family: "Montserrat";
  font-size: 21.7px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 140% */
  border-radius: 36.889px;
  border: 4px solid var(--primary);
  transition: all 0.4s linear;
  &:hover {
    background: var(--white);
    color: var(--primary);
  }
}
input {
  outline: none;
  &::-webkit-inner-spin-button {
    display: none;
  }
  &::-webkit-outer-spin-button {
    display: none;
  }
}

.pointer {
  cursor: pointer;
}
.v-center {
  display: flex;
  align-items: center;
}
.h-center {
  display: flex;
  justify-content: center;
}
.overflowX-hidden {
  overflow-x: hidden !important;
}
@media screen and (max-width: 1440px) {
  .main-btn {
    padding: 12px 30px;
    font-size: 18px;
  }
}
@media screen and (max-width: 1024px) {
  .main-btn {
    padding: 8px 15px;
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .mobile-br {
    display: none;
  }
  .main-btn {
    padding: 5px 10px;
    font-size: 16px;
    border-width: 2px;
  }

  .sm-text-center {
    text-align: center !important;
  }
}

.img-60 {
  width: 60px;
  height: 60px;
}
