.showcase-sec {
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .left {
    height: 100vh;
    max-width: 40%;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 53px;
    row-gap: 20px;
    h1 {
      font-size: 5.7vw;
      line-height: 100%;
      font-family: "Montserrat";
      color: var(--primary);
      font-weight: 700;
    }
    .para-div {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      .large-para {
        color: var(--black);
        font-family: "Montserrat";
        line-height: 175%;
      }
    }
  }
}

@media screen and (max-width: 1550px) {
  .showcase-sec {
    .left {
      padding: 20px;
      row-gap: 20px;
      .para-div {
        row-gap: 15px;
        .large-para {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .showcase-sec {
    height: unset;
    padding: 50px 40px 0px 40px;
    video {
      display: none;
    }
    .left {
      height: unset;
      max-width: 100%;
      padding: 0px;
      row-gap: 10px;
      h1 {
        font-size: 5.7vw;
      }
      .para-div {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        .large-para {
          font-size: 16px;
          line-height: 140%;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .showcase-sec {
    padding: 40px 20px 0px 20px;
    .left {
      height: unset;
      max-width: 100%;
      padding: 0px;
      row-gap: 10px;
      h1 {
        font-size: 6.5vw;
      }
      .para-div {
        row-gap: 5px;
        .large-para {
          font-size: 15px;
        }
      }
    }
  }
}
