.heading-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 90px;
  h1 {
    color: rgba(216, 217, 255, 0.4);
    font-family: "Montserrat";
    font-size: 148.77px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  .main-head {
    position: absolute;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    border-radius: 39px;
    background: rgba(111, 0, 255, 0.2);
    mix-blend-mode: multiply;
    padding: 8px 10px;
    width: max-content;
    height: fit-content;
    div {
      border-radius: 39px;
      background: rgba(111, 0, 255, 0.01);
      mix-blend-mode: multiply;
      backdrop-filter: blur(40px);
      padding: 2px 38px;
      h2 {
        color: #000;
        text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.25);
        font-family: "Montserrat";
        font-size: 52px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .heading-sec {
    margin-bottom: 60px;
    h1 {
      font-size: 8vw;
    }
    .main-head {
      padding: 5px 8px;

      div {
        padding: 2px 20px;
        h2 {
          font-size: 3.5vw;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .heading-sec {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 600px) {
  .heading-sec {
    margin-bottom: 30px;
    h1 {
      font-size: 50px;
    }
    .main-head {
      padding: 5px 8px;

      div {
        padding: 2px 20px;
        h2 {
          font-size: 25px;
        }
      }
    }
  }
}
