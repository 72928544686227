.blockfever-sec {
  display: flex;
  padding: 100px;
  .inner {
    display: flex;
    align-items: center;
    max-width: 1500px;
    margin: 0px auto;
    column-gap: 100px;
    justify-content: space-between;
    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      row-gap: 35px;
      .head {
        position: relative;

        .bottom-img {
          position: absolute;
          bottom: 0;
          width: 109px;
          height: 7px;
          object-fit: contain;
          left: 20%;
        }
        .top-img {
          position: absolute;
          top: 0;
          transform: rotate(180deg);
          width: 69px;
          height: 4.431px;
          object-fit: contain;
        }
        .main-head {
          display: flex;
          align-items: center;
          h3 {
            color: var(--black);
            text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.25);
            font-family: "Montserrat";
            font-size: 47px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
          }
          div {
            border-radius: 39px;
            background: rgba(111, 0, 255, 0.2);
            mix-blend-mode: multiply;
            backdrop-filter: blur(40px);
            padding: 10px;
            .shadow-title {
              border-radius: 39px;
              background: rgba(111, 0, 255, 0.01);
              mix-blend-mode: multiply;
              backdrop-filter: blur(40px);
              padding: 0px 30px;
            }
          }
        }
      }
      .large-para {
        line-height: 175%;
        color: var(--black);
      }
      .tabs-para {
        row-gap: 30px;
        .bottom-head {
          display: flex;
          align-items: center;
          column-gap: 9px;
          border-radius: 10px 0px 0px 10px;
          padding: 13px 68px 13px 11px;
          width: 100%;
          max-width: 450px;

          cursor: pointer;
          svg {
            visibility: hidden;
          }
          h3 {
            text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.25);
            font-family: "Montserrat";
            font-size: 23px;
            font-style: normal;
            line-height: 100%;
            font-weight: 500;
          }

          &.active {
            transition: all 0.4s linear;

            background: linear-gradient(
              90deg,
              #6f00ff 0%,
              rgba(111, 0, 255, 0) 100%
            );
            color: var(--white);
            h3 {
              font-weight: 700;
            }

            svg {
              visibility: visible;
            }
          }
        }
      }
    }
    .right {
      width: 50%;
      .img-outer {
        max-width: 619px;
        margin-left: auto;
        width: 100%;
        padding: 21px;
        border-radius: 55.785px;
        box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.09);
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          border-radius: 52px;
          position: relative;
          z-index: 2;
        }
        .fever-back {
          position: absolute;
          left: 0;
          z-index: 1;
          bottom: 0;
          // object-fit: cover;
          animation: imgrotate 6s infinite ease-in-out;
        }
        @keyframes imgrotate {
          0% {
            transform: rotate(0);
          }
          // 50% {
          //   transform: rotate(90deg);
          // }
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .blockfever-sec {
    padding: 100px 80px;
    .inner {
      column-gap: 60px;
      .left {
        row-gap: 20px;
        .head {
          .main-head {
            h3 {
              font-size: 35px;
            }
            div {
              padding: 8px;
              .shadow-title {
                padding: 0px 25px;
              }
            }
          }
        }
        .large-para {
          font-size: 18px;
          line-height: 150%;
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .blockfever-sec {
    padding: 80px 60px;
    .inner {
      column-gap: 40px;
      .left {
        row-gap: 20px;
        .head {
          .bottom-img {
            width: 80px;
          }
          .top-img {
            width: 40px;
          }
          .main-head {
            h3 {
              font-size: 30px;
            }
          }
        }
        .large-para {
          font-size: 18px;
          line-height: 150%;
        }
        .tabs-para {
          row-gap: 20px;
          .bottom-head {
            padding: 10px 40px 10px 10px;

            h3 {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .blockfever-sec {
    padding: 50px 40px;
    .inner {
      row-gap: 20px;
      flex-direction: column;
      .left {
        row-gap: 20px;
        width: 100%;
        .head {
          .bottom-img {
            width: 80px;
          }
          .top-img {
            width: 40px;
          }
          .main-head {
            h3 {
              font-size: 30px;
            }
          }
        }
        .large-para {
          font-size: 18px;
          line-height: 150%;
        }
        .tabs-para {
          row-gap: 10px;
          .bottom-head {
            padding: 10px 40px 10px 10px;

            h3 {
              font-size: 20px;
            }
          }
        }
      }
      .right {
        width: 100%;
        .img-outer {
          max-width: 400px;
          margin: 0px auto;
          padding: 15px;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .blockfever-sec {
    padding: 25px 20px;
    .inner {
      row-gap: 10px;
      .left {
        row-gap: 10px;
        width: 100%;
        .head {
          .bottom-img {
            width: 80px;
          }
          .top-img {
            width: 40px;
          }
          .main-head {
            h3 {
              font-size: 23px;
            }
          }
        }
        .large-para {
          font-size: 15px;
          line-height: 130%;
        }
        .tabs-para {
          row-gap: 5px;
          .bottom-head {
            padding: 10px 10px 10px 10px;

            h3 {
              font-size: 18px;
            }
          }
        }
      }
      .right {
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          border-radius: 20px;
        }
        .img-outer {
          max-width: 350px;
          max-height: 350px;
          margin: 0px auto;
          padding: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 20px;
          }
        }
      }
    }
  }
}
