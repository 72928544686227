.portfolio-sec {
  .portfoliocards-sec {
    padding: 200px 58px 35px 58px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 128px;
    .cards-tabs {
      .tabs-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 30px;
        .large-para {
          cursor: pointer;
          border-radius: 30px;
          padding: 10px 66px;
          color: var(--black);

          text-align: center;
          font-family: "Montserrat";
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .active {
          border: 3px solid var(--black);
        }
      }
    }
    .portfolio-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px 58px;
    }
    .pagination {
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
      padding: 16px 32px;

      .pag-inner {
        display: flex;
        align-items: center;
        column-gap: 50px;
        .pag-item {
          cursor: pointer;
          color: var(--black);
          font-family: "Montserrat";
          width: 40px;
          height: 40px;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .active {
          color: var(--white);
          background: var(--primary);

          border-radius: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .portfolio-sec {
    .portfoliocards-sec {
      padding: 150px 50px 35px 50px;
      row-gap: 68px;
      .cards-tabs {
        .tabs-inner {
          column-gap: 20px;
          .large-para {
            padding: 8px 40px;
            font-size: 18px;
          }
        }
      }
      .portfolio-cards {
        gap: 20px 30px;
      }
      .pagination {
        padding: 12px 28px;
        .pag-inner {
          column-gap: 30px;
          .pag-item {
            font-size: 22px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .portfolio-sec {
    .portfoliocards-sec {
      padding: 100px 50px 35px 50px;
      row-gap: 70px;
      .cards-tabs {
        .tabs-inner {
          column-gap: 10px;
          .large-para {
            padding: 8px 40px;
            font-size: 16px;
          }
        }
      }
      .portfolio-cards {
        gap: 20px 30px;
        grid-template-columns: repeat(2, 1fr);
      }
      .pagination {
        padding: 10px 25px;
        column-gap: 20px;
        .pag-item {
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .portfolio-sec {
    .portfoliocards-sec {
      padding: 50px 40px 35px 40px;
      row-gap: 50px;
      .cards-tabs {
        .tabs-inner {
          column-gap: 6px;
          width: 650px;
          .large-para {
            padding: 5px 20px;
            font-size: 15px;
            width: fit-content;
          }
          .active {
            border: 2px solid var(--black);
          }
        }
      }
      .portfolio-cards {
        gap: 15px 20px;
        grid-template-columns: repeat(2, 1fr);
      }
      .pagination {
        padding: 10px 25px;
        .pag-inner {
          column-gap: 20px;
          .pag-item {
            font-size: 18px;
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .portfolio-sec {
    .portfoliocards-sec {
      padding: 40px 20px 40px 20px;
      row-gap: 20px;
      .cards-tabs {
        overflow-x: scroll;
        width: 90vw;
        .tabs-inner {
          column-gap: 6px;
          width: 600px;
          .large-para {
            padding: 5px 20px;
            font-size: 15px;
          }
        }
      }
      .portfolio-cards {
        gap: 15px;
        grid-template-columns: repeat(1, 1fr);
      }
      .pagination {
        padding: 8px 15px;
        .pag-inner {
          column-gap: 10px;
          .pag-item {
            font-size: 15px;
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}
