.bridges-sec {
  padding: 100px 80px;
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  .brige-cards {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    max-width: 1758px;
    margin: 0px auto;
    gap: 34px 26px;
    .bridge-card {
      width: 10vw;
      height: 10vw;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 17.497px;
      background: #fff;
      box-shadow: 0px 0px 19.858px 0px rgba(0, 0, 0, 0.12);
      box-shadow: 0px 0px 19.858px 0px rgba(0, 0, 0, 0.12);
      img {
        width: 60%;
        height: 60%;
        object-fit: contain;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .bridges-sec {
    padding: 80px 60px;
    row-gap: 60px;
    .brige-cards {
      gap: 20px 15px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .bridges-sec {
    padding: 0px 40px 50px 40px;
    row-gap: 40px;
    .brige-cards {
      gap: 15px 10px;
    }
  }
}
@media screen and (max-width: 600px) {
  .bridges-sec {
    padding: 0px 20px;
    row-gap: 30px;
    .brige-cards {
      gap: 15px 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .bridge-card {
        width: 20vw;
        height: 20vw;
      }
    }
  }
}
