section.quantum {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

section.about-quantum {
  min-height: 750px;
  display: flex;
  .container {
    gap: 35px;
    .left-content {
      width: 50%;
      h2 {
        color: #6f00ff;
        font-family: Montserrat;
        font-size: 73.509px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
      p {
        color: #000;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 170%;
        margin-top: 35px;
      }
    }
    .right-content {
      width: 50%;

      img {
        max-width: 100%;
      }
    }
  }
}
