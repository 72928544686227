.iot-services-sec {
    padding: 60px 75px 100px 75px;
    .iot-services {
      padding: 35px 0px;
      .services-content {
        border-radius: 31px;
        background: rgba(145, 148, 252, 0.3);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 15px;
        background-color: white;
      
      
      .service-card {
        cursor: pointer;
        max-width: 390px;
        min-height: 440px; 
        height: 100%; 
        
        display: flex;
        flex-direction: column;
        justify-content: start; 
        align-items: center;
        padding: 35px 28px;
        gap: 15px;
        border-radius: 18.339px;
        background: #fff;
        position: relative;
        text-align: center;
        box-shadow: 0px 422px 118px 0px rgba(116, 89, 166, 0.00), 
                    0px 270px 108px 0px rgba(116, 89, 166, 0.01), 
                    0px 152px 91px 0px rgba(116, 89, 166, 0.05), 
                    0px -152px 91px 0px rgba(116, 89, 166, 0.05), 
                    0px 68px 68px 0px rgba(116, 89, 166, 0.09), 
                    0px 17px 37px 0px rgba(116, 89, 166, 0.10);
      
      .desc {
        color: #2B2B2B;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        display: flex;
        justify-content: center;
        line-height: normal;
      }
      
          img {
            transition: all 0.4s linear;
            position: relative;
          }
           .icons{
             display: flex;
             justify-content: center;
             align-items: center;
             position: relative;
            .bgIcon{
                width: 120px;
                height: 120px;
            }
             .icon{
                position: absolute;
                // top: 50%;
                width: 58px;
                height: 58px;
                padding: 5px;

             }

           }
          h6 {
            transition: all 0.4s linear;
            color: #2B2B2B;
            font-family: Montserrat;
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: unset;
            text-wrap: wrap;
            word-wrap: break-word;

            span{
                text-wrap: wrap;
                letter-spacing: normal;
            word-wrap: break-word;

            }
          }
          &::after {
            content: attr(data-content);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 17px;
            height: 17px;
            border-radius: 93.214px;
            background: #6f00ff;
            color: #fff;
            font-family: Montserrat;
            font-size: 1px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            transition: all 0.4s linear;
            opacity: 0;
          }
          &:hover {
            img {
              transition: all 0.4s linear;
              opacity: 10%;
            }
            h6 {
              transition: all 0.4s linear;
              opacity: 10%;
            }
            &::after {
              opacity: 1;
              transition: all 0.4s linear;
              width: 187px;
              height: 187px;
              font-size: 20px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 1650px) {
    .iot-services-sec {
      padding: 60px 50px 100px 50px;
      .iot-services {
        padding: 31px 20px;
        .services-content {
          padding: 80px 20px;
        }
      }
    }
  }
  @media screen and (max-width: 1440px) {
    .iot-services-sec {
      .iot-services {
        .services-content {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }
  @media screen and (max-width: 1250px) {
    .iot-services-sec {
      .iot-services {
        .services-content {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .iot-services-sec {
      padding: 60px 20px 100px 20px;
      .iot-services {
        padding: 31px 0px;
        .services-content {
          grid-template-columns: repeat(2, 1fr);
          .service-card {
            max-width: 100%;
            padding: 24px;

          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .iot-services-sec {
      padding: 0px 20px;
      .iot-services {
        padding: 25px 0px;
        .services-content {
          grid-template-columns: repeat(1, 1fr);
          padding: 20px;
          border-radius: 10px;
          margin: 0px auto;
          max-width: 350px;
          .service-card {
            height: 200px;
            gap: 10px;
            border-radius: 10px;
            background: #fff;
            padding: 20px;
            position: relative;
            img {
              width: 50px;
              height: 50px;
            }
            h6 {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  