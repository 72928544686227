.services-sec {
  padding: 60px 75px 100px 75px;
  .services {
    padding: 31px 0px;
    .services-content {
      padding: 80px 40px;
      border-radius: 31px;
      background: rgba(145, 148, 252, 0.3);
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 30px;
      .service-card {
        cursor: pointer;
        max-width: 303.038px;
        height: 309.151px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        border-radius: 18.339px;
        background: #fff;
        padding: 40px;
        position: relative;
        img {
          transition: all 0.4s linear;
          position: relative;
        }
        h6 {
          transition: all 0.4s linear;
          color: #000;
          font-family: Montserrat;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          text-align: center;
          letter-spacing: unset;
        }
        &::after {
          content: attr(data-content);
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          width: 17px;
          height: 17px;
          border-radius: 93.214px;
          background: #6f00ff;
          color: #fff;
          font-family: Montserrat;
          font-size: 1px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          transition: all 0.4s linear;
          opacity: 0;
        }
        &:hover {
          img {
            transition: all 0.4s linear;
            opacity: 10%;
          }
          h6 {
            transition: all 0.4s linear;
            opacity: 10%;
          }
          &::after {
            opacity: 1;
            transition: all 0.4s linear;
            width: 187px;
            height: 187px;
            font-size: 20px;
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .services-sec {
    padding: 60px 50px 100px 50px;
    .services {
      padding: 31px 20px;
      .services-content {
        padding: 80px 20px;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .services-sec {
    .services {
      .services-content {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
@media screen and (max-width: 1250px) {
  .services-sec {
    .services {
      .services-content {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .services-sec {
    padding: 60px 20px 100px 20px;
    .services {
      padding: 31px 0px;
      .services-content {
        grid-template-columns: repeat(2, 1fr);
        .service-card {
          max-width: 100%;
          padding: 30px;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .services-sec {
    padding: 0px 20px;
    .services {
      padding: 25px 0px;
      .services-content {
        grid-template-columns: repeat(1, 1fr);
        padding: 20px;
        border-radius: 10px;
        margin: 0px auto;
        max-width: 350px;
        .service-card {
          height: 200px;
          gap: 10px;
          border-radius: 10px;
          background: #fff;
          padding: 20px;
          position: relative;
          img {
            width: 50px;
            height: 50px;
          }
          h6 {
            font-size: 18px;
          }
        }
      }
    }
  }
}
