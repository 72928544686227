.servicespage-sec {
  .top-sec {
    padding: 157px 100px;
    .top-inner {
      max-width: 1029px;
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      row-gap: 60px;
      .img-sec {
        width: 100%;
        position: relative;
        .main-img {
          width: 100%;
          animation: mainanimate 3s linear infinite;
        }
        .left-img {
          position: absolute;
          left: -100px;
          top: 50%;
          bottom: 50%;
          transform: translate(0%, -50%);
          animation: leftanimate 3s linear infinite;
        }
        .rightimg-div {
          position: absolute;
          right: -65px;
          top: 50%;
          bottom: 50%;
          transform: translate(0, -50%);
          animation: rightanimate 3s linear infinite;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        @keyframes mainanimate {
          0% {
            transform: translateY(0);
          }
          35% {
            transform: translateY(20px);
          }
          55% {
            transform: translateY(20px);
          }
          85% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(0);
          }
        }
        @keyframes leftanimate {
          0% {
            top: 50%;
            bottom: 50%;
          }
          35% {
            top: 49%;
            bottom: 51%;
          }
          55% {
            top: 49%;
            bottom: 51%;
          }
          85% {
            top: 50%;
            bottom: 50%;
          }
          100% {
            top: 50%;
            bottom: 50%;
          }
        }
        @keyframes rightanimate {
          0% {
            transform: rotate(0deg);
          }
          35% {
            transform: rotate(2deg);
          }
          55% {
            transform: rotate(2deg);
          }
          85% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .servicespage-sec {
    .top-sec {
      padding: 100px 80px;
      .top-inner {
        max-width: 800px;
        .img-sec {
          .left-img {
            left: -70px;
            width: 180px;
            height: 370px;
            object-fit: cover;
          }
          .rightimg-div {
            right: -60px;

            img {
              width: 180px;
              height: 370px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .servicespage-sec {
    .top-sec {
      padding: 40px 20px;
      .top-inner {
        max-width: unset;

        row-gap: 10px;
        .img-sec {
          width: 70%;
          margin: 0px auto;
          .left-img {
            left: -9%;
            width: 16vw;
            height: 30vw;
            object-fit: cover;
          }
          .rightimg-div {
            right: -8%;
            img {
              width: 16vw;
              height: 30vw;
            }
          }
          @keyframes mainanimate {
            0% {
              transform: translateY(0);
            }
            35% {
              transform: translateY(10px);
            }
            55% {
              transform: translateY(10px);
            }
            85% {
              transform: translateY(0);
            }
            100% {
              transform: translateY(0);
            }
          }
        }
      }
    }
  }
}
