.work-flow {
  .work-flow-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 200px 50px 100px 50px;
    position: relative;
    .work-flow-card {
      max-width: 360px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .header {
        background: var(--white);
        width: 136px;
        height: 136px;
        border-radius: 30px;
        background: #fff;
        box-shadow: 0px 14px 59px 7px rgba(111, 0, 255, 0.09);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.6s linear;
        .notshow-icon {
          transition: all 0.6s linear;
        }
        .show-icon {
          display: none;
          transition: all 0.6s linear;
        }
        &:hover {
          background: #6f00ff;
          transition: all 0.6s linear;
          .notshow-icon {
            display: none;
            transition: all 0.6s linear;
          }
          .show-icon {
            transition: all 0.6s linear;
            display: unset;
          }
        }
      }
    }
  }
  .divider {
    margin-top: -60px;
  }
}
.divider {
  display: flex;
  align-items: center;
  gap: 20px;

  span {
    background: #e4e8f6;
    border-radius: 100%;
    display: block;
  }
  .circle-center {
    width: 12.705px;
    height: 12.705px;
  }

  .circle-1 {
    width: 2.541px;
    height: 2.541px;
  }
  .circle-2 {
    width: 5.082px;
    height: 5.082px;
  }
  .circle-3 {
    width: 7.623px;
    height: 7.623px;
  }
  .circle-4 {
    width: 10.164px;
    height: 10.164px;
  }
}

@media screen and (max-width: 1200px) {
  .work-flow {
    .divider {
      gap: 10px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .work-flow {
    .work-flow-content {
      padding: 100px 40px;
      .work-flow-card {
        .header {
          width: 100px;
          height: 100px;
        }
        label {
          font-size: 16px;
        }
        p {
          font-size: 15px;
        }
      }
    }
    .divider {
      margin-top: -130px;
      gap: 8px;
    }
  }
}
@media screen and (max-width: 800px) {
  .work-flow {
    .work-flow-content {
      padding: 50px 30px;
      .work-flow-card {
        .header {
          width: 80px;
          height: 80px;
        }
        label {
          font-size: 12px;
        }
        p {
          font-size: 12px;
        }
      }
    }
    .divider {
      margin-top: -100px;
      gap: 2px;
    }
  }
}
@media screen and (max-width: 600px) {
  .work-flow {
    .work-flow-content {
      padding: 25px 20px;
      flex-direction: column;
      gap: 30px;
      .work-flow-card {
        .header {
          width: 80px;
          height: 80px;
          border-radius: 15px;
          .show-icon {
            display: none;
            transition: all 0.6s linear;
            width: 30px;
            height: 30px;
          }
          .notshow-icon {
            width: 30px;
            height: 30px;
          }
          &:hover {
            background: #6f00ff;
            transition: all 0.6s linear;
            .notshow-icon {
              display: none;
              transition: all 0.6s linear;
            }
            .show-icon {
              transition: all 0.6s linear;
              display: unset;
            }
          }
        }

        label {
          font-size: 18px;
          margin-top: 10px !important;
        }
        p {
          font-size: 15px;
          margin-top: 10px !important;
        }
      }
    }
    .divider {
      display: none;
    }
  }
}
