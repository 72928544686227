/** Swiper styles **/

:root {
    --swiper-pagination-color: rgba(255, 255, 255, 1);
    --swiper-pagination-bullet-inactive-color: rgba(255, 255, 255, 1);
  }
  
  .swiper {
    user-select: none;
    box-sizing: border-box;
    overflow: visible;
    width: 100%;
  
    height: 100%;
  
    padding: 0px 0px;
  }
  
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
  
    overflow: hidden;
  }
  
  .swiper-slide-bg-image {
    position: absolute;
    left: -10%;
    top: -10%;
    width: 120%;
    height: 120%;
    z-index: 0;
  }
  
  .swiper-slide-content {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    transform: translate3d(0, 0, 0);
  }
  
  .swiper-slide-3f38 {
background-image: url(/src/assets/img/aiprojectsback.webp);

  }
  .swiper-slide-bg-image-c61b {
    object-fit: cover;
    border-radius: inherit;
  }
  .swiper-slide-content-2ef8 {
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    padding: 48px 48px;
    gap: 0px;
  }
  .swiper-slide-text-79be {
    color: rgba(255, 255, 255, 1);
    text-align: left;
    font-size: 24px;
    line-height: 1.5;
    font-weight: bold;
  }