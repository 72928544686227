.navbar-sec {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  padding: 53px 100px 0px 100px;
  .navbar {
    padding: 0px;
    display: flex;
    align-items: center;
    background: transparent;
    .navbar-brand {
      display: flex;
      align-items: center;
      img {
        width: 215px;
        height: 50px;
        object-fit: contain;
        padding: 0px;
      }
    }
    .navbar-collapse {
      display: flex;
      align-items: center;
      .navbar-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 50px;
        border-radius: 31.927px;
        background: #fff;
        backdrop-filter: blur(38.867130279541016px);
        box-shadow: 0px 0px 19px 13px rgba(0, 0, 0, 0.03);

        padding: 20px 80px;
        .nav-item {
          padding: 0px;
          a {
            padding: 0px;
            color: var(--black);
            font-family: "Montserrat";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration: none;
          }
          a.active {
            position: relative;

            &::after {
              content: "";

              background: url(/assets/icons/navafter.svg);
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              width: 57px;
              height: 20px;
              position: absolute;
              bottom: -20px;
              left: 50%;
              right: 50%;
              transform: translate(-50%);
            }
          }
        }
      }
      .nav-btn {
        padding: 5px 6px;
        border-radius: 25px;
        border: 2px solid #9498ff;
        background: #6b00fa;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        button {
          padding: 9px 18px;
          border-radius: 23.621px;
          background: rgba(145, 148, 252, 0.6);
          box-shadow: 0px 0px 3.779px 0px rgba(0, 0, 0, 0.25);
          color: var(--white);
          font-family: "Montserrat";
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .navbar-sec {
    padding: 40px 40px 0px 40px;
    .navbar {
      .navbar-brand {
        img {
          width: 200px;
          height: 70px;
        }
      }
      .navbar-collapse {
        .navbar-nav {
          column-gap: 30px;

          padding: 20px 50px;
          .nav-item {
            padding: 0px;
            a {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1280px) {
  .navbar-sec {
    padding: 40px 40px 0px 40px;
    .navbar {
      .navbar-brand {
        img {
          width: 170px;
          height: 60px;
          object-fit: contain;
        }
      }
      .navbar-collapse {
        .navbar-nav {
          padding: 15px 40px;
          column-gap: 20px;
          .nav-item {
            padding: 0px;
            a {
              font-size: 16px;
            }
            a.active {
              &::after {
                content: "";

                background: url(/assets/icons/navafter.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                width: 40px;
                height: 15px;
                position: absolute;
                bottom: -15px;
                left: 50%;
                right: 50%;
                transform: translate(-50%);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1386px){
  .navbar-sec{
    .navbar{
      gap: 15px;
      .navbar-collapse {
        gap: 10px;
        .navbar-nav{
          .nav-item{
            a{
              font-size: 12px;
            }
          }
        }
    }

    }

  }  
} 

@media screen and (max-width: 991px) {
  .navbar-sec {
    padding: 20px;
    z-index: 10;
    display: none;
    .navbar-toggler {
      padding: 0px;
      border: none;
    }
  }

  .navbar-brand {
    img {
      width: 140px;

      object-fit: contain;
    }
  }
  .navbar-collapse {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    border: 1px solid #fff;
    background: #f6f9ff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
    row-gap: 5px;
    .navbar-nav {
      row-gap: 5px;
      border-radius: 20px;
      padding: 0;
      width: 100%;
      border: none;
      background: #f6f9ff;
      box-shadow: unset;

      .nav-item {
        padding: 0px;
        a {
          font-size: 16px;
          color: var(--headingcolor) !important;
        }
        a.active {
          color: #2abe02 !important;
        }
      }
    }
  }
}
.ant-drawer-content-wrapper {
  width: 100% !important;
}
.mb-nav {
  background-color: #000;
  .nav-item {
    padding: 0px;
    a {
      padding: 0px;
      color: var(--white);
      font-family: "Montserrat";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-decoration: none;
    }
    a.active {
      position: relative;
      color: #6b00fa !important;

      &::after {
        content: "";
        background: #6b00fa;
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: -5px;
        left: 50%;
        right: 50%;
        transform: translate(-50%);
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    left: 0;
    top: 80px;
    width: 100%;

    z-index: 9;
  }
  .nav-btn {
    padding: 5px 6px;
    border-radius: 25px;
    border: 2px solid #9498ff;
    background: #6b00fa;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    width: fit-content;
    button {
      padding: 9px 18px;
      border-radius: 23.621px;
      background: rgba(145, 148, 252, 0.6);
      box-shadow: 0px 0px 3.779px 0px rgba(0, 0, 0, 0.25);
      color: var(--white);
      font-family: "Montserrat";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.ant-drawer-body {
  padding: 0 !important;
  background-color: #000;
}
