.deciphering-sec {
  padding: 129px 50px;

  .heading {
    max-width: 1225px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .detail-para {
      text-align: center;
      line-height: 170%;
    }
  }
  .Deciphering-cards {
    padding: 200px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    position: relative;

    .backimg {
      position: absolute;
      object-fit: cover;
      bottom: 0;
    }
    .card-content {
      max-width: 286px;
      max-height: 399px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      padding: 57px 30px;
      border-radius: 17px;
      background: #fff;
      box-shadow: 0px 0px 19px 13px rgba(0, 0, 0, 0.03);
      position: relative;
      z-index: 3;
      overflow: hidden;

      img {
        transition: all 0.8s linear;
        position: relative;
      }
      label {
        margin-top: 16px;
        transition: all 0.8s linear;
        position: relative;
        text-align: center;
      }
      p {
        position: relative;
        transition: all 0.8s linear;
        margin-top: 30px;
        min-height: 125px;
      }
      .circle {
        position: absolute;
        width: 286px;
        height: 286px;
        border-radius: 286px;
        background: #6f00ff;
        bottom: -65%;
        transition: all 0.8s linear;
        z-index: 1;
      }
      &:hover {
        .circle {
          transform: translateY(-65%);
          width: 100%;
          height: 100%;
          border-radius: 0;
        }
        img {
          transition: all 1s linear;
          z-index: 3;
          filter: brightness(0) invert(1);
        }
        label {
          z-index: 3;
          color: #fff;
        }
        p {
          z-index: 3;
          color: #fff;
        }
      }
    }
  }
}
@media screen and (max-width: 1350px) {
  .deciphering-sec {
    padding: 100px 50px;
    .heading {
      .detail-para {
        line-height: 140%;
        font-size: 18px;
      }
    }
    .Deciphering-cards {
      padding: 100px 0px;
      gap: 30px;
      .card-content {
        padding: 30px;
      }
    }
  }
}
@media screen and (max-width: 1260px) {
  .deciphering-sec {
    padding: 80px 50px;
    .Deciphering-cards {
      padding: 60px 0px;

      gap: 30px;
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
      .backimg {
        width: 100%;
      }
      .card-content {
        padding: 30px;
        max-width: 100%;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .deciphering-sec {
    padding: 50px 20px;
    .heading {
      .detail-para {
        line-height: 140%;
        font-size: 16px;
      }
    }
    .Deciphering-cards {
      .card-content {
        p {
          font-size: 14px;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .deciphering-sec {
    padding: 25px 20px;
    .heading {
      row-gap: 10px;
      .detail-para {
        line-height: 120%;
        font-size: 15px;
      }
    }
    .Deciphering-cards {
      grid-template-columns: repeat(1, 1fr);
      padding: 20px 0px 0px 0px;
      row-gap: 15px;
      .card-content {
        max-height: unset;
        max-width: 350px;
        margin: 0px auto;
        padding: 20px;
        border-radius: 10px;

        img {
          width: 60px;
          height: 60px;
        }
        label {
          margin-top: 10px;
          font-size: 18px;
        }
        p {
          margin-top: 10px;
          font-size: 15px;
        }
        .circle {
          width: 200px;
          height: 200px;
          border-radius: 100px;
          bottom: -60%;
        }
        &:hover {
          .circle {
            width: 100%;
            height: 100%;
            transform: scale(2);
            bottom: 0px;
          }
        }
      }
      .backimg {
        display: none;
      }
    }
  }
}
