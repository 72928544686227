.banner {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  position: relative;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 50px;
  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    .mobile {
      display: none;
    }
  }
  h1 {
    position: relative;
    z-index: 2;
  }
}
@media screen and (max-width: 1280px) {
  .banner {
    h6 {
      font-size: 3vw;
      letter-spacing: 5px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .banner {
    height: 80vh;
    padding: 0px 40px;
  }
}
@media screen and (max-width: 600px) {
  .banner {
    height: 60vh;
    video {
      .desktop {
        display: none;
      }
      .mobile {
        display: unset;
      }
    }
    h1 {
      font-size: 12vw;
    }
    h2 {
      font-size: 4.5vw;
      text-align: center !important;
    }
    h6 {
      font-size: 4.5vw;
      text-align: center !important;
      letter-spacing: 2px;
    }
  }
}
