.heading-1 {
  span {
    position: relative;
    // &:after {
    //   content: "";
    //   position: absolute;
    //   left: 50%;
    //   top: 50%;
    //   transform: translate(-50%, -50%);
    //   z-index: -1;
    //   border-radius: 52.684px;
    //   background: #6f00ff;
    //   width: 103px;
    //   height: 105.368px;
    // }
  }
}
