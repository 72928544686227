.footer-sec {
  display: flex;
  background: var(--black);
  justify-content: center;
  .left {
    display: flex;
    flex-direction: column;
    row-gap: 34px;
    padding: 128px 80px 128px 100px;

    .footer-logo {
      width: 215px;
      height: 49.939px;
      object-fit: cover;
    }
    span {
      color: var(--white);
      text-align: left;
      font-family: "Montserrat";
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 233.333%;
      max-width: 703px;
    }
    .location {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      justify-content: space-between;
      gap: 30px;

      .address {
        display: flex;
        align-items: center;
        column-gap: 5px;
        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          border-radius: 100%;
        }
        .country {
          display: flex;
          flex-direction: column;
          span {
            color: #c1c1c1;
            font-family: "Montserrat";
            font-size: 12px;
            font-style: normal;
          }
          .bold-span {
            line-height: 140%;
            font-weight: 500;
          }
          .light-span {
            line-height: 120%;
            font-weight: 400;
          }
        }
      }
    }
  }
  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 40px;
    padding: 40px;
    border-left: 1px solid var(--white);
    border-right: 1px solid var(--white);
    a {
      display: flex;
      align-items: center;
      column-gap: 10px;
      img {
        width: 38px;
        height: 38px;
        object-fit: cover;
      }
      .large-para {
        color: var(--white);
        text-align: justify;
        font-family: "Roboto";
        line-height: normal;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 20px;
    padding: 100px 80px;
    h3 {
      color: var(--white);

      font-family: "Roboto";
      // font-size: 80.145px;
      font-size: 4.19vw;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      text-transform: uppercase;
      text-align: center;
      span {
        color: var(--primary);
      }
    }
    label {
      color: var(--light);
      font-weight: 300;
      text-align: center;
    }
    .pages-links {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 30px;
      margin-top: 50px;
      a {
        color: #c1c1c1;
        text-align: justify;
        font-family: "Roboto";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

@media screen and (max-width: 1710px) {
  .footer-sec {
    .left {
      justify-content: center;
      row-gap: 25px;
      padding: 100px 60px 100px 70px;

      .footer-logo {
        width: 200px;
        height: 48px;
        object-fit: contain;
      }
      .location {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        width: 100%;
        max-width: 400px;
        .address {
          width: 330px;
        }
      }
    }
    .center {
      justify-content: center;
      row-gap: 30px;
      padding: 30px;
      a {
        display: flex;
        align-items: center;
        column-gap: 10px;
        img {
          width: 38px;
          height: 38px;
          object-fit: cover;
        }
      }
    }
    .right {
      justify-content: center;
      row-gap: 20px;
      padding: 100px 70px;
      .pages-links {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: 30px;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .footer-sec {
    justify-content: space-around;
    .left {
      padding: 80px 40px;
      .footer-logo {
        width: 180px;
        height: 42px;
        object-fit: contain;
      }
      span {
        font-size: 15px;
        line-height: 190%;
        max-width: 420px;
      }
    }
    .center {
      row-gap: 30px;
      padding: 40px 20px;
      a {
        display: flex;
        align-items: center;
        column-gap: 10px;
        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
        }
      }
    }
    .right {
      row-gap: 20px;
      padding: 60px 30px;
      h3 {
        font-size: 3vw;
      }
      label {
        font-size: 16px;
      }
      .pages-links {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .footer-sec {
    flex-wrap: wrap;
    .left {
      width: 100%;
      row-gap: 20px;
      padding: 50px 40px;

      .footer-logo {
        width: 170px;
        height: 45px;
        object-fit: contain;
      }
      span {
        line-height: 200%;
        max-width: 100%;
      }
      .location {
        max-width: unset;
        justify-content: flex-start;
      }
    }
    .center {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 30px 50px;
      padding: 40px;
      border-left: none;
      border-right: unset;
      border-top: 1px solid var(--white);
      border-bottom: 1px solid var(--white);
      a {
        width: 120px;
        display: flex;
        align-items: center;
        column-gap: 10px;
      }
    }
    .right {
      width: 100%;
      row-gap: 15px;
      padding: 50px;
      h3 {
        font-size: 4.19vw;
        text-align: left;
      }
      label {
        font-size: 15px;
        text-align: left;
      }
      .pages-links {
        justify-content: flex-start;

        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .footer-sec {
    flex-direction: column;
    .left {
      row-gap: 15px;
      padding: 40px 20px;

      .footer-logo {
        width: 160px;
        height: 40px;
        object-fit: contain;
      }
      span {
        color: var(--white);
        font-family: "Montserrat";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 180%;
      }
    }
    .center {
      padding: 40px 20px;
      border-left: unset;
      border-right: unset;
    }
    .right {
      row-gap: 10px;
      padding: 40px 20px;
      h3 {
        font-size: 35px;
        span {
          color: var(--primary);
        }
      }
      label {
        font-size: 15px;
      }
      .pages-links {
        justify-content: flex-start;
        column-gap: 20px;
        margin-top: 20px;
      }
    }
  }
}
