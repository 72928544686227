.q-sevices {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding: 50px 100px 100px 20px;
  max-width: 1500px;
  margin: 0px auto;

  .inner{
    display: flex;
    flex-direction: column;
    row-gap: 50px;
  }

  .service1,
  .service4 {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    column-gap: 50px;

    .left {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        color: var(--black);
        text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.25);
        font-family: "Montserrat";
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
      }

      .desc {
        position: relative;
        margin-top: 40px;

        img {
          position: absolute;
          top: -40%;
          left: -100px;
        }
        p{
          text-align: left;
        }
      }
    }

    .right {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: fit-content;
      margin: 20px auto;

      .img-outer {
        max-width: 619px;
        margin-left: auto;
        width: 100%;
        padding: 21px;
        border-radius: 55.785px;
        box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.09);
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        border .inner-img {
          width: 600px;
          height: 600px;
        }

        img {
          width: 100%;
          border-radius: 52px;
          position: relative;
          z-index: 2;
        }

        .fever-back {
          position: absolute;
          left: 0;
          z-index: 1;
          bottom: 0;
          // object-fit: cover;
          animation: imgrotate 6s infinite ease-in-out;
        }

        @keyframes imgrotate {
          0% {
            transform: rotate(0);
          }

          // 50% {
          //   transform: rotate(90deg);
          // }
        }
      }
    }
  }

  .service1,
  .service2,
  .service3,
  .service4 {
    .para {
      color: #454545;
      text-align: center;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }


  }

  .service2,
  .service3 {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .head-sec{
      padding-top: 30px;
      .para{
        padding-top: 30px;
        text-transform: capitalize;
      }
    }

    .cards{
      .service2-cards {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
  
        .card1 {
          display: flex;
          column-gap: 32px;
          // width: 638px;
          width: 45%;
          min-height: 180px;
          border-radius: 16px;

  
          margin: 0 auto;
          border: 3px solid #fff;
          //   background: rgba(255, 255, 255, 0.8);
          box-shadow: 0px 422px 118px 0px rgba(116, 89, 166, 0),
            0px 270px 108px 0px rgba(116, 89, 166, 0.01),
            0px 152px 91px 0px rgba(116, 89, 166, 0.05),
            0px 68px 68px 0px rgba(116, 89, 166, 0.09),
            0px 17px 37px 0px rgba(116, 89, 166, 0.1);
          backdrop-filter: blur(36.099998474121094px);
          padding: 32px;
  
          .card-img {
            width: 82px;
            height: 82px;
          }
  
          .card-desc {
            .card-head {
              color: #454545;
              font-family: Montserrat;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              margin-bottom: 2px;
            }
          }
        }
      }
    }



    .service2-img {
      position: absolute;
      top: 3%;
      left: 12%;
    }

    .para {
      width: 80%;
      margin: 20px auto;
      padding-top: 20px;
    }
  }
}

  @media screen and (max-width: 1500px) {
    .q-sevices {
      padding: 50px 100px;

    }
  }

  @media screen and (max-width: 1280px) {
    .q-sevices {
      padding: 40px 80px;
      padding-bottom: 20px;


      .service1,
      .service4 {
        .left {}

        .right {}
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .q-sevices {
      padding: 30px 60px;
      padding-bottom: 0px;


      .service2,
      .service3 {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

    
        .cards{
          .service2-cards {
            display: flex;
            flex-wrap: nowrap;
            row-gap: 20px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
      
            .card1 {
           
              display: flex;
              // width: 638px;
              align-items: center;
              width: 100%;
              min-height: 120px;
              border-radius: 16px;
      
              margin: 0 auto;
              border: 2px solid #fff;
              //   background: rgba(255, 255, 255, 0.8);
              box-shadow: 0px 222px 60px 0px rgba(116, 89, 166, 0),
                0px 270px 108px 0px rgba(116, 89, 166, 0.01),
                0px 152px 91px 0px rgba(116, 89, 166, 0.05),
                0px 68px 68px 0px rgba(116, 89, 166, 0.09),
                0px 17px 37px 0px rgba(116, 89, 166, 0.1);
              padding: 34px 20px;
      
              .card-img {
                width: 60px;
                height: 60px;
                display: flex;
              }
      
              .card-desc {
                .card-head {
                  display: block;
                  color: #454545;
                  font-family: Montserrat;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                }
              }
            }
          }
        }
    
        .service2-img {
          position: absolute;
          top: 3%;
          left: 12%;
        }
    
        .para {
          width: 100%;
          margin: 20px auto;
          padding-top: 20px;
        }
      }


        .service1,
        .service4 {
          grid-template-columns: repeat(1, 1fr);
          width: 100%;

          .left{
            p{
              text-align: center !important;
            }
          }

          .right {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: fit-content;
            margin: 20px auto;
      
            .img-outer {
              max-width: 619px;
              margin-left: auto;
              width: 100%;
              padding: 21px;
              border-radius: 55.785px;
              box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.09);
              overflow: hidden;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;

              width: 60%;
              margin: 0 auto;
      

      

      

            }
          }
        }

        
      }
    }
  

  @media screen and (max-width: 600px) {
    .q-sevices {
      padding: 20px !important;
      .inner {
        row-gap: 30px ;

        .service2, .service3{
          .head-sec{
            .para{
              margin: 0px auto 10px;
              width: 90%;
              font-size: 12px;
              
            }
          }
          .cards{
            .service2-cards{
              gap: 10px ;

              .card1{
                padding: 10px 20px ;
                min-height: 0px;
                display: flex;
                align-items: center;

                .card-img{
                  display: flex ;
                  align-items: center;
                }

                .card-desc{
                  p{
                    font-size: 12px;
                  }
                }
              }

            }
          }
        }


        .left {
          width: 100%;

          .head {
            .bottom-img {
              width: 80px;
            }

          }
        }

        .right {
          width: 100%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 20px;
          }

          .img-outer {
            max-width: 350px;
            max-height: 350px;
            margin: 0px auto;
            padding: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 20px;
            }
          }
        }
      }

      .service1, .service4{
        margin-top: 10px;
        .desc{
          margin-top: 10px !important;
          // background-color: green;
          p{
            font-size: 14px;
          }
        }
      }

      .service2, .service3{
        .head-sec{
          padding-top: 0px;

        }
        .cards{
          .service2-cards{
            .card1{
              align-items: center;
              img{
                width: 50px;
              }
              .card-desc{
                width: 100%;
                p{
                  font-size: 14px;
                  width: 100%;
                }
                .card-head{
                  font-size: 14px;
                  width: 100%;
                  font-weight: 600;

                }
                
              }
            }
          }
        }
        .para{
          font-size: 14px;
        }
      }
    }
  }


