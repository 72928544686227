.homeservices-sec {
  padding: 73px 136px;

  .head-img {
    width: 100%;
    margin: 0px auto;
    display: flex;
  }
  .service-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 41px;
    padding-top: 80px;

    .content {
      width: 100%;
      height: 380px;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      padding: 25px 20px;
      border-radius: 26.112px;
      row-gap: 6px;
      position: relative;
      overflow: hidden;
      .soon-img {
        z-index: 3;
        position: absolute;
        right: 5%;
        left: 5%;
        width: 90%;
        transition: all 0.3s linear;
        &.show-img {
          top: 15px;
          opacity: 1;
        }
        &.hide-img {
          top: -100px;
          opacity: 0;
        }
      }
      .back-image {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 26.112px;
        width: 100%;
        height: 100%;
        z-index: 2;
        img {
          position: relative;
          z-index: 2;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .text-content {
        transition: all 0.4s linear;
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        h2 {
          transition: all 0.6s linear;
          color: #fff;
          font-family: Roboto;
          font-size: 50.598px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          text-transform: uppercase;
          border-bottom: 4px solid #fff;
          width: 58px;
        }
        h4 {
          transition: all 0.6s linear;
          color: #fff;
          font-family: Roboto;
          font-size: 22.657px;
          font-style: normal;
          font-weight: 800;
          line-height: 125%;
          text-transform: uppercase;
        }
      }
      .para {
        transition: all 0.6s linear;
        position: absolute;
        z-index: 3;
        width: 65%;
        opacity: 0;
        color: #fff;
        text-align: left;
        font-family: "Montserrat";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .arrow {
        position: absolute;
        bottom: -8%;
        right: -5%;
        z-index: 3;
        width: 116.589px;
        height: 116.589px;
        background: #6f00ff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s linear;
      }
      .arrow2 {
        position: absolute;
        bottom: -30%;
        right: -30%;
        z-index: 3;
        width: 126px;
        height: 126px;
        background: #fff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s linear;
        opacity: 0;
      }
      .black-layer {
        content: " ";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 26.112px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 27.52%, #000 100%);
        z-index: 2;
        transition: all 0.6s linear;
      }
      transition: all 0.4s linear;

      &:hover {
        .text-content {
          transition: all 0.4s linear;
        }
        .para {
          opacity: 1;
          transition: all 0.6s linear;
          position: relative !important;
        }
        .arrow {
          transform: translate(80px, 80px);
        }
        .arrow2 {
          opacity: 1;
          bottom: -8%;
          right: -5%;
          transition: all 0.4s linear;
          transform: translate(-0px, -0px);
        }
        .black-layer {
          transition: all 0.6s linear;
          border-radius: 26.112px;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.25) -0.07%,
            #6f00ff 100%
          );
          backdrop-filter: blur(2.5px);
        }
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .homeservices-sec {
    padding: 50px 100px;
    .service-cards {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
@media screen and (max-width: 1340px) {
  .homeservices-sec {
    padding: 50px;
    .service-cards {
      gap: 20px;
      .content {
        padding: 20px 15px;
        .text-content {
          row-gap: 8px;
          h2 {
            font-size: 40px;
          }
          h4 {
            font-size: 20px;
            line-height: 110%;
          }
        }

        .arrow {
          bottom: -8%;
          right: -5%;
          width: 100px;
          height: 100px;
          img {
            width: 30px;
          }
        }
        .arrow2 {
          width: 110px;
          height: 110px;
          img {
            width: 30px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .homeservices-sec {
    padding: 50px 20px;
    .service-cards {
      grid-template-columns: repeat(2, 1fr);
      .content {
        max-width: 100%;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .homeservices-sec {
    padding: 0px 20px;
    .service-cards {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 20px;
      .content {
        max-width: 350px;
        margin: 0px auto;
      }
    }
  }
}
