.marquee-sec {
  background: url("/assets/img/marque.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 5.2vw 0px;
  .marquee-inner {
    display: flex;
    align-items: center;
    column-gap: 100px;
    margin-right: 100px;
    h6 {
      font-size: 1.88vw;
      color: var(--white);
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: unset;
    }
  }
}

@media screen and (max-width: 1024px) {
  .marquee-sec {
    .marquee-inner {
      column-gap: 40px;
      margin-right: 40px;
    }
  }
}
@media screen and (max-width: 600px) {
  .marquee-sec {
    .marquee-inner {
      column-gap: 30px;
      margin-right: 30px;
      h6 {
        font-size: 2.6vw;
      }
    }
  }
}
