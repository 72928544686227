.animatedheading2-sec {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  h3 {
    color: var(--black);
    text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.25);
    font-family: "Montserrat";
    font-size: 2.7vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: no-wrap;
  }
  .top-div {
    display: flex;
    align-items: center;
    div {
      border-radius: 39px;
      background: rgba(111, 0, 255, 0.2);
      mix-blend-mode: multiply;
      backdrop-filter: blur(40px);
      padding: 7px 8px;
      .shadow-title {
        border-radius: 39px;
        background: rgba(111, 0, 255, 0.01);
        mix-blend-mode: multiply;
        backdrop-filter: blur(40px);
        padding: 0px 10px;
      }
    }
  }
  .top-img {
    position: absolute;
    top: -14px;
    animation: topmove 6s infinite ease-in-out;
  }
  @keyframes topmove {
    0% {
      transform: translateX(-200px);
      width: 175px;
    }
    50% {
      transform: translateX(200px);

      width: 90px;
    }
    100% {
      transform: translateX(-200px);

      width: 175px;
    }
  }
  @keyframes bottommove {
    0% {
      transform: translateX(200px);
      width: 175px;
    }
    50% {
      transform: translateX(-200px);

      width: 90px;
    }
    100% {
      transform: translateX(200px);

      width: 175px;
    }
  }
  .bottom-img {
    position: absolute;
    bottom: -14px;
    animation: bottommove 6s infinite ease-in-out;
  }
}

@media screen and (max-width: 1500px) {
  .animatedheading2-sec {
    @keyframes topmove {
      0% {
        transform: translateX(-150px);
        width: 100px;
      }
      50% {
        transform: translateX(150px);

        width: 80px;
      }
      100% {
        transform: translateX(-150px);

        width: 100px;
      }
    }
    @keyframes bottommove {
      0% {
        transform: translateX(150px);
        width: 100px;
      }
      50% {
        transform: translateX(-150px);

        width: 80px;
      }
      100% {
        transform: translateX(150px);

        width: 100px;
      }
    }
  }

  .q-sevices{
    .animatedheading2-sec {
      h3 {
        font-size: 25px !important;
        white-space: nowrap;

      }
     }
  }
}

@media screen and (max-width: 1100px) {
  .animatedheading2-sec {
    .top-img {
      top: -9px;
    }
    .bottom-img {
      bottom: -9px;
    }
    @keyframes topmove {
      0% {
        transform: translateX(-80px);
        width: 60px;
      }
      50% {
        transform: translateX(80px);

        width: 40px;
      }
      100% {
        transform: translateX(-80px);

        width: 60px;
      }
    }
    @keyframes bottommove {
      0% {
        transform: translateX(80px);
        width: 60px;
      }
      50% {
        transform: translateX(-80px);

        width: 40px;
      }
      100% {
        transform: translateX(80px);

        width: 60px;
      }
    }
  }

  .q-sevices{
    .animatedheading2-sec {
      h3 {
        font-size: 20px !important;

      }
     }
  }
}
@media screen and (max-width: 600px) {
  .animatedheading2-sec {
    h3 {
      font-size: 18px;
    }
    .top-img {
      display: none;
    }
    .bottom-img {
      display: none;
    }
    @keyframes topmove {
      0% {
        transform: translateX(-30px);
        width: 40px;
      }
      50% {
        transform: translateX(30px);

        width: 30px;
      }
      100% {
        transform: translateX(-30px);

        width: 40px;
      }
    }
    @keyframes bottommove {
      0% {
        transform: translateX(30px);
        width: 40px;
      }
      50% {
        transform: translateX(-30px);

        width: 30px;
      }
      100% {
        transform: translateX(30px);

        width: 40px;
      }
    }
  }

  .q-sevices{
    .animatedheading2-sec {
      h3 {
        font-size: 16px !important;
      }
     }
  }
}

@media screen and (max-width: 450px) {
  .q-sevices{
    .animatedheading2-sec {
      h3 {
        font-size: 10px !important;
        padding-bottom: 5px;
      }
     }
  }
}

// for quantampage 
.q-sevices{
  .animatedheading2-sec {
    h3 {
      font-size: 25px;
    }
   }
}