.statistics {
  padding: 100px 170px 140px 170px;
  .stat-card-wrapper {
    gap: 30px;
  }
  .stats-card {
    border-radius: 64px;
    border: 4px solid #6f00ff;
    max-width: 345px;
    width: 100%;
    padding: 0px 10px 10px;
    position: relative;
    overflow: hidden;
    transition: all 0.7s linear;
    justify-content: center;
    h2,
    p {
      transition: all 0.7s linear;
    }
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -80%;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      background: #6f00ff;
      transition: all 0.7s linear;
      z-index: -1;
    }
    &:hover {
      h2,
      p {
        color: #fff;
      }
      &::after {
        height: 400px;
        width: 600px;
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .statistics {
    padding: 80px 120px;

    .stats-card {
      &::after {
        bottom: -90%;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .statistics {
    padding: 60px 70px;

    .stats-card {
      &::after {
        bottom: -110%;
      }
      h2 {
        // font-size: 2.6vw;
      }
      p {
        font-size: 20px;
      }
    }
  }
}
@media screen and (max-width: 1140px) {
  .statistics {
    .details {
      padding: 0px 10px !important;
      width: 90% !important;
      font-size: 18px;
    }
    .stat-card-wrapper {
      gap: 20px;
    }
    .stats-card {
      border: 3px solid #6f00ff;
      padding: 0px 10px 10px 10px;
      border-radius: 30px;
      h2,
      p {
        transition: all 0.7s linear;
      }
      h2 {
        font-size: 20px;
      }
      p {
        font-size: 16px;
      }
      &::after {
        left: 50%;
        transform: translateX(-50%);
        bottom: -80%;
        width: 60px;
        height: 60px;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .statistics {
    padding: 50px 40px;
    .stat-card-wrapper {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      gap: 20px;
    }
    .stats-card {
      max-width: 300px;
      h2,
      p {
        transition: all 0.7s linear;
      }
    }
    .stats-card:nth-child(odd) {
      margin-left: auto;
    }
    .details {
      margin: 0px auto;
      margin-top: 20px !important;
      font-size: 16px;
      padding: 0px !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .statistics {
    padding: 25px 10px !important;
    .stat-card-wrapper {
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      display: flex !important;
    }
    .stats-card {
      width: 250px;
      margin: 0px auto !important;
      h2,
      p {
        transition: all 0.7s linear;
      }
    }

    .details {
      width: 100% !important;
    }
  }
}
