.explore-sec {
  background: url("/assets/img/exploreback.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  .explore-inner {
    padding: 200px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 54px;
    .left {
      width: 60%;
      img {
        width: 100%;
        height: 100%;
      }
      .exploresecond {
        display: none;
      }
      &:hover {
        .exploresecond {
          display: block;
        }
        .explorefirst {
          display: none;
        }
      }
    }
    .right {
      width: 40%;
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      h1 {
        color: var(--primary);
        font-family: "Montserrat";
        font-size: 5vw;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;
      }
      button {
        width: fit-content;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .explore-sec {
    .explore-inner {
      padding: 150px 50px;
      column-gap: 20px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .explore-sec {
    .explore-inner {
      padding: 50px 40px 0px 40px;
      column-gap: 20px;
    }
  }
}
@media screen and (max-width: 600px) {
  .explore-sec {
    background: unset;
    .explore-inner {
      padding: 40px 20px 0px 20px;
      row-gap: 20px;
      flex-direction: column-reverse;

      .left {
        width: 100%;
        max-width: 500px;
        margin: 0px auto;
      }
      .right {
        width: 100%;
        align-items: center;
        row-gap: 10px;
        h1 {
          text-align: center;
        }
      }
    }
  }
}
