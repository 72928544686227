.innovation {
  padding: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
  max-width: 1588px;
  margin: 0px auto;
  .left-content {
    width: 40%;
    span {
      color: #fff;
      font-family: Montserrat;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 35px; /* 233.333% */
      border-radius: 30px;
      text-transform: uppercase;
      background: rgba(111, 0, 255, 0.5);
      padding: 10px 20px;
    }
    h3 {
      color: #000;
      font-family: Roboto;
      font-size: 52px;
      font-style: normal;
      font-weight: 800 !important;
      line-height: normal;
      margin: 30px 0 50px 0;
    }
    .main-btn {
      text-transform: capitalize;
    }
  }
  .right-content {
    width: 60%;
    .output-wrapper {
      display: flex;
      align-items: center;
      gap: 50px;
      margin-top: 50px;
      & > div {
        width: 366px;
        border-radius: 12px;
        background: #fff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 8px 16px;
        position: relative;
        h3,
        p {
          transition: all 0.3s linear;
          position: relative;
          z-index: 2;
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 12px;
          height: 100%;
          border-radius: 16px;
          background: linear-gradient(90deg, #6f00ff 0%, #120097 100%);
          transition: all 0.3s linear;
        }

        &:hover {
          &::after {
            width: 100%;
          }
          h3,
          p {
            color: #fff;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .innovation {
    padding: 80px 100px;
    .left-content h3 {
      font-size: 40px;
      margin: 30px 0 30px 0;
    }
  }
}
@media screen and (max-width: 1500px) {
  .innovation {
    padding: 60px 80px;
    column-gap: 50px;
    .left-content {
      h3 {
        font-size: 35px;
        line-height: 100%;
        margin: 20px 0 30px 0;
      }
    }
    .right-content {
      .fw-medium {
        font-size: 18px;
      }
      .output-wrapper {
        gap: 20px;
        margin-top: 30px;

        & > div {
          width: 320px;
          h3 {
            font-size: 35px;
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .innovation {
    padding: 50px 60px;
    gap: 20px;
    max-width: 1588px;
    margin: 0px auto;
    .left-content {
      width: 35%;
      h3 {
        font-size: 35px;
        margin: 20px 0;
      }
    }
    .right-content {
      width: 65%;
      .fw-medium {
        font-size: 16px;
      }
      .output-wrapper {
        gap: 15px;
        margin-top: 20px;
        & > div {
          padding: 8px 10px 8px 16px;
          p {
            font-size: 15px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .innovation {
    padding: 0px 40px;
    flex-direction: column;
    gap: 20px;
    margin: 0px auto;
    .left-content {
      width: 100%;

      h3 {
        font-size: 35px;
        margin: 20px 0;
      }
    }
    .right-content {
      width: 100%;
      .output-wrapper {
        gap: 20px;
        margin-top: 20px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .innovation {
    padding: 25px 20px;
    flex-direction: column;
    gap: 30px;
    .left-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h3 {
        font-size: 25px;
        text-align: center;
      }
      button {
        width: 150px;
        height: unset;
        padding: 10px;
        font-size: 16px;
        line-height: normal;
      }
    }
    .right-content {
      width: 100%;
      p {
        font-size: 16px;
        text-align: center;
      }
      h3 {
        font-size: 40px;
      }
      .output-wrapper {
        gap: 30px;
        margin-top: 30px;
        flex-direction: column;
      }
    }
  }
}
