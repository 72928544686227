.teammembercard {
  display: flex;
  flex-direction: column;
  user-select: none;
  .top-div {
    position: relative;
    padding: 0px 36px;
    margin-bottom: -8px;
    .member-img {
      display: flex;
      object-fit: cover;
      margin: 0px auto;
      position: relative;
      z-index: 3;
    }
    .img-back {
      transition: all 0.4s linear;
      opacity: 0;
      position: absolute;
      bottom: 40px;
      left: 50%;
      right: 50%;
      transform: translate(-50%, 0);
      width: 63%;
      height: 60%;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #6f00ff 100%
      );

      filter: blur(42.63816833496094px);
    }
    .social-links {
      transition: all 0.4s linear;
      display: flex;
      column-gap: 20px;
      z-index: 5;
      position: absolute;
      bottom: -40px;
      left: 50%;
      right: 50%;
      transform: translate(-50%, 0);
    }
  }
  .detail {
    // min-height: 109px;
    position: relative;
    z-index: 6;
    width: 100%;
    background: url(/assets/img/bodmemberback.webp);
    background-repeat: no-repeat;
    transition: all 0.4s linear;
    background-size: 100% 100%;
    filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.14));
    padding: 18px 20px;
    h3 {
      color: var(--black);
      font-family: "Montserrat";
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 140% */
      transition: all 0.4s linear;
    }
    .small-para {
      transition: all 0.4s linear;
      color: var(--primary);
      font-weight: 500;
      text-align: left;
      padding-right: 25%;
    }
    .octa-div {
      transition: all 0.4s linear;

      border: 3px solid var(--primary);
      border-radius: 100%;
      width: 62px;
      height: 62px;
      position: absolute;
      right: -20px;
      top: 50%;
      bottom: 50%;
      transform: translate(0, -50%);
      background: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        transition: all 0.4s linear;

        color: var(--primary);
      }
    }
  }
  &:hover {
    .top-div {
      .img-back {
        opacity: 1;
      }
      .social-links {
        display: flex;
        column-gap: 20px;
        z-index: 5;
        position: absolute;
        bottom: 20px;
      }
    }
    .detail {
      background: url(/assets/img/bodmemberback2.webp);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      h3 {
        color: var(--white);
      }
      .small-para {
        color: var(--white);
      }
      .octa-div {
        border: 3px solid var(--white);

        background: var(--primary);

        svg {
          color: var(--white);
          transform: rotate(180deg);
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .teammembercard {
    .top-div {
      padding: 0px 20px;
    }
    .detail {
      // min-height: 104.8px;
      h3 {
        font-size: 22px;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .teammembercard {
    .top-div {
      padding: 0px 15px;
      .member-img {
        width: 200px;
        height: 320px;
      }
      .social-links {
        transition: all 0.4s linear;
        display: flex;
        column-gap: 20px;
        z-index: 5;
        position: absolute;
        bottom: -40px;
      }
    }
    .detail {
      padding: 15px;
      // min-height: 94px;
      h3 {
        font-size: 20px;
      }
      .small-para {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .teammembercard {
    .top-div {
      padding: 0px 15px;

      .social-links {
        column-gap: 10px;
        a {
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .detail {
      padding: 15px;
      min-height: unset;
      h3 {
        font-size: 18px;
      }
    }
    &:hover {
      .top-div {
        .social-links {
          display: flex;
          column-gap: 10px;
          bottom: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .teammembercard {
    max-width: 350px;
    .top-div {
      .member-img {
        width: unset;
        height: 300px;
      }
      .img-back {
        opacity: 1;
      }

      .social-links {
        column-gap: 10px;
        bottom: 20px;
      }
    }
    .detail {
      max-width: 300px;
      margin: 0px auto;
      padding: 15px 18px;
      h3 {
        font-size: 18px;
      }
      .large-para {
        font-size: 15px;
      }
    }
  }
}
