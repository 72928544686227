.lets-talk {
  transition: all 0.5s linear;
  padding: 100px 150px;
  .primary-wrapper {
    border-radius: 311px;
    width: 100%;
    height: 438px;
    outline: 4px dashed;
    outline-color: transparent;
    position: relative;
    overflow: hidden;
    transition: all 0.5s linear;
    .logo-slider {
      background: #fff;
      width: 438px;
      height: 100%;
      position: absolute;
      transform: translate(0%, 0%);
      right: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      transition: all 0.5s linear;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
      z-index: 2;
      img {
        filter: brightness(1) invert(0);
        transition: all 0.3s linear;
      }
    }

    .primary-content {
      padding: 100px 100px 100px 250px;
      background: linear-gradient(180deg, #6f00ff 0%, #25007f 100%);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      // left: 50px;
      top: 0;
      opacity: 1;
      transform: scale(1);
      transition: all 0.4s linear;
      h6 {
        color: #fff;
        text-align: justify;
        font-family: Roboto;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: normal !important;
        transform: scale(1);
      }
      h3 {
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 60px;
        transition: all 0.4s linear;
      }
    }

    .hovered-content {
      display: flex;
      justify-content: center;
      padding: 100px;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: all 0.4s linear;
      transform: scale(0);
      opacity: 0;
      h1 {
        color: #000;
        text-align: justify;
        font-family: Roboto;
        font-size: 161.266px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: relative;
        // right: 50px;
      }
    }

    &:hover {
      outline-color: #25007f;
      .logo-slider {
        left: unset;
        right: 0;
        // transform: translate(266%, 0%);
        background: radial-gradient(
          50% 50% at 50% 50%,
          #6f00ff 0%,
          #25007f 100%
        );
        img {
          filter: brightness(0) invert(1);
        }
      }

      .primary-content {
        opacity: 0;
        h3,
        h6 {
          transform: scale(0);
        }
      }

      .hovered-content {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .lets-talk {
    padding: 80px 120px;
    .primary-wrapper {
      height: 350px;
      .logo-slider {
        width: 348px;
        height: 100%;
      }
      .primary-content h3 {
        font-size: 40px;
      }

      .hovered-content {
        h1 {
          font-size: 130px;
          margin-right: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .lets-talk {
    padding: 60px 70px;

    .primary-wrapper {
      height: 300px;

      .logo-slider {
        width: 300px;
        height: 100%;
      }
      .primary-content h3 {
        font-size: 35px;
      }

      .hovered-content {
        h1 {
          font-size: 130px;
          margin-right: 100px;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .lets-talk {
    padding: 60px 70px;

    .primary-wrapper {
      height: 250px;

      .logo-slider {
        width: 250px;
        height: 100%;
      }
      .primary-content h3 {
        font-size: 25px;
        line-height: 100%;
        margin-top: 20px;
      }

      .hovered-content {
        h1 {
          font-size: 70px;
          margin-right: 100px;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .lets-talk {
    padding: 50px 40px;

    .primary-wrapper {
      height: 250px;

      .logo-slider {
        width: 250px;
        height: 100%;
      }
      .primary-content h3 {
        font-size: 25px;
        line-height: 100%;
        margin-top: 20px;
      }

      .hovered-content {
        h1 {
          font-size: 70px;
          margin-right: 100px;
        }
      }
    }
  }
}
@media screen and (max-width: 770px) {
  .lets-talk {
    padding: 0px 10px 25px 10px;
    margin: 0px 0;
    .primary-wrapper {
      height: 130px;

      .logo-slider {
        width: 200px;
        height: 100%;
        visibility: hidden;
      }

      .primary-content {
        padding: 20px 10px;
        opacity: 1;
        transform: scale(1);
        transition: all 0.4s linear;
        h3 {
          font-size: 20px;
          margin-top: 0;
        }
        h6 {
          display: none;
        }
      }
      .hovered-content {
        padding: 0px;

        h1 {
          font-size: 50px;
          margin-right: 0px;
        }
      }
    }
  }
}
