.homebod-sec {
  padding: 100px;
  .detail-para {
    margin: 44px auto 100px auto;
    max-width: 784px;
    line-height: 175%;
    color: var(--black);
    text-align: center;
    font-family: "Montserrat";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
  .team-cards {
    max-width: 1482px;
    margin: 0px auto;
    display: grid;
    column-gap: 100px;
    row-gap: 100px;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    // margin-bottom: 130px;
  }
  .main-btn {
    display: flex;
    margin: 0px auto;
  }
}

@media screen and (max-width: 1500px) {
  .homebod-sec {
    padding: 80px;
    .detail-para {
      margin: 44px auto 60px auto;
    }
    .team-cards {
      column-gap: 60px;
      row-gap: 50px;
      // margin-bottom: 80px;
    }
    .main-btn {
      display: flex;
      margin: 0px auto;
    }
  }
}

@media screen and (max-width: 1280px) {
  .homebod-sec {
    padding: 60px;
    .detail-para {
      margin: 30px auto 50px auto;
      line-height: 120%;
      font-size: 18px;
    }
    .team-cards {
      column-gap: 40px;
      row-gap: 40px;
      // margin-bottom: 60px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .homebod-sec {
    padding: 40px;
    .detail-para {
      line-height: 130%;
      font-size: 18px;
    }
    .team-cards {
      display: grid;
      row-gap: 20px;
      grid-template-columns: repeat(1, 1fr);
      place-items: center;
      margin: 0px auto;
      // margin-bottom: 40px;
      gap: 30px;
      .teammembercard {
        width: 46%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .homebod-sec {
    padding: 25px 20px;
    .detail-para {
      margin: 10px auto 10px auto;
      line-height: 130%;
      font-size: 15px;
    }
    .team-cards {
      max-width: 1482px;
      margin: 0px auto;
      display: grid;
      row-gap: 20px;
      grid-template-columns: repeat(1, 1fr);
      place-items: center;
      // margin-bottom: 30px;
      .teammembercard {
        width: 100%;
      }
    }
  }
}
