.astro-sec {
  padding: 100px 50px;
  .inner {
    max-width: 1750px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    column-gap: 27px;
    .left {
      width: 62%;
      display: flex;
      flex-direction: column;
      row-gap: 29px;
      h1 {
        font-size: 6.57vw;
        line-height: 100%;
        font-family: "Montserrat";
        text-transform: uppercase;
        font-weight: 700;
        color: var(--primary);
      }
      .large-para {
        color: var(--black);
        font-family: "Montserrat";
        font-weight: 500;
        line-height: 175%;
      }
    }
    .right {
      width: 38%;
      position: relative;
      // height: 938px;
      .astro-main {
        // position: absolute;
        // top: 0;
        // right: 0;
        // bottom: 0;
        // left: 0;
        animation: mainanimate 2s linear infinite;
        width: 100%;
        height: 100%;
      }

      .astro-back {
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        animation: backanimate 2s linear infinite;
      }

      @keyframes backanimate {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(0.7deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }

      @keyframes mainanimate {
        0% {
          transform: translateY(0px);
        }
        50% {
          transform: translateY(8px);
        }
        100% {
          transform: translateY(0px);
        }
      }
    }
  }
}

@media screen and (max-width: 1550px) {
  .astro-sec {
    padding: 100px 50px;
    .inner {
      column-gap: 20px;
      .left {
        row-gap: 20px;
        h1 {
          line-height: 92%;
        }
        .large-para {
          line-height: 150%;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .astro-sec {
    padding: 80px 50px;
    .inner {
      .left {
        row-gap: 15px;
        h1 {
          font-size: 6.3vw;
        }
        .large-para {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .astro-sec {
    padding: 50px 40px;
    .inner {
      column-gap: 20px;
      .left {
        row-gap: 10px;
        width: 60%;
        h1 {
          font-size: 5.5vw;
        }
        .large-para {
          font-size: 16px;
        }
      }
      .right {
        width: 40%;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .astro-sec {
    padding: 40px 20px;
    .inner {
      flex-direction: column;
      gap: 20px;
      .left {
        width: 100%;
        row-gap: 10px;
        h1 {
          font-size: 6.2vw;
          line-height: 82%;
          text-align: center;
          .mobile-hide {
            display: none;
          }
        }
        .large-para {
          font-family: 15px;
          line-height: 130%;
          text-align: center;
        }
      }
      .right {
        width: 100%;
        max-width: 300px;
        max-height: 500px;
        object-fit: contain;
        margin: 0px auto;
        .astro-main {
          width: 100%;
          height: 100%;
          max-width: 300px;
          max-height: 500px;
        }
        .astro-back {
          height: 100%;
          width: 100%;
          max-width: 300px;
          max-height: 500px;
        }
      }
    }
  }
}
