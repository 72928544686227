.partner-sec {
  display: flex;
  flex-direction: column;
  padding: 50px 100px;
  max-width: 1500px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1500px;
    margin: 0px auto;

    .content {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      margin: 0 auto;
      // margin-top: -250px;

      .frame {
        .framImg {
          width: 100%;
        }
      }

      .infinity {
        position: absolute;
        top: 9%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .para {
        position: absolute;
        top: 30%;
        width: 80%;
        margin: 0 auto;

        color: #454545;
        text-align: center;
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }
}


@media screen and (max-width: 1400px) {
  .partner-sec {
    padding: 40px 80px;
    .heading-sec {
      margin-bottom: 114px;
    }
    .inner {
      // margin-top: -30px;
      .content {
        margin-top: -220px;
        .framImg {
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .partner-sec {
    padding: 30px 60px;
    .heading-sec {
      margin-bottom: 100px;
    }
    .inner {
      margin-top: -10px;
      .content {
        .para {
          width: 100%;
          padding: 10px;
          font-size: 16px;
          text-transform: capitalize;
          width: 80%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .partner-sec {
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 75px;
    height: 100%;
    .heading-sec {
      margin-bottom: 100px;
     
      .inner-head {
        margin-top: -18px;
      }
    }
    .inner {
      .content {
        .para {
          font-size: 14px;
          width: 100%;
          top: 16% !important;
        }
      }
    }
  }
  .animatedheading2-sec {
    .top-div {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
}

