.contact-banner {
  padding: 300px 100px 50px 100px;
  position: relative;
  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  .bg-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.705);
    z-index: -1;
  }
  h1 {
    color: #6f00ff;
  }
  .social-warapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 60px;
    & > div {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
    }
    img {
      box-shadow: 0px 14px 59px 7px rgba(111, 0, 255, 0.09);
      border-radius: 30px;
      cursor: pointer;
    }
    p {
      // width: 347px;
      width: 18.4vw;

      height: 62px;
      border-radius: 34.5px;
      border: 2px solid #6f00ff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 60px;
      color: #000;
      cursor: pointer;
    }

    .divider {
      position: absolute;
      transform: scale(0.6);
      right: -35%;
      top: 60px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .contact-banner {
    padding: 250px 80px 50px 80px;
    .social-warapper {
      gap: 20px;
      margin-top: 40px;
      justify-content: space-between;
      p {
        width: 19vw;
        height: 50px;
        margin-top: 40px;
        font-size: 17px;
      }
      img {
        width: 8vw;
      }
      .divider {
        position: absolute;
        transform: scale(0.6);
        right: -45%;
        top: 60px;
        column-gap: 15px;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .contact-banner {
    padding: 200px 60px 50px 60px;
    .social-warapper {
      gap: 20px;
      margin-top: 40px;
      justify-content: space-between;
      p {
        width: 20vw;
        height: 50px;
        margin-top: 20px;
        font-size: 15px;
      }
      img {
        width: 8vw;
        border-radius: 20px;
      }
      .divider {
        position: absolute;
        transform: scale(0.6);
        right: -45%;
        top: 25%;
        column-gap: 15px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .contact-banner {
    padding: 150px 40px 50px 40px;
    .social-warapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      gap: 20px;
      margin-top: 50px;
      p {
        max-width: 300px;
        width: 100%;
        height: 50px;
        margin-top: 20px;
      }

      .divider {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .contact-banner {
    padding: 100px 20px 40px 20px;
    h1 {
      font-size: 10vw;
    }
    .social-warapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      margin-top: 20px;

      img {
        width: 80px;
        height: 80px;
        border-radius: 20px;
      }
      p {
        // width: 347px;
        width: 250px;
        font-size: 16px;
        height: 45px;
        margin-top: 20px;
      }

      .divider {
        display: none;
      }
    }
  }
}
