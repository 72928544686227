.careeroppertunity-sec {
  padding: 100px;
  background: #f9f9f9;
  overflow: hidden;
  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 60px;
    max-width: 1614px;
    margin: 0px auto;
    .left {
      width: 54%;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      h1 {
        color: var(--black);
        font-family: "Montserrat";
        font-size: 5.5vw;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        span {
          color: var(--primary);
        }
      }
      .main-btn {
        width: fit-content;
      }
    }
    .right {
      width: 46%;
      display: flex;
      justify-content: flex-end;
      position: relative;
      .center-img {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
      }
      .animated-img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        animation: rotateimage 2s infinite ease-in;
      }
      @keyframes rotateimage {
        0% {
          transform: rotate(0deg);
        }
        30% {
          transform: rotate(120deg);
        }
        50% {
          transform: rotate(120deg);
        }
        80% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .careeroppertunity-sec {
    padding: 80px;
    .inner {
      column-gap: 40px;
      .left {
        h1 {
          font-size: 6.2vw;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .careeroppertunity-sec {
    padding: 40px;
  }
}
@media screen and (max-width: 600px) {
  .careeroppertunity-sec {
    padding: 25px 20px;
    .inner {
      flex-direction: column;
      row-gap: 30px;
      .left {
        width: 100%;
        h1 {
          font-size: 25px;
        }
      }
      .right {
        width: 100%;
        max-width: 350px;
        max-height: 350px;
        margin: 0px auto;
      }
    }
  }
}
