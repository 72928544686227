.swiper {
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;

  height: 100%;

  padding: 0px 0px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

.swiper-slide-content-cd26 {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 48px 48px;
  gap: 0px;
}
.swiper-slide-text-79be {
  color: rgba(255, 255, 255, 1);
  text-align: left;
  font-size: 24px;
  line-height: 1.5;
  font-weight: bold;
}

/* ---- */
.swiper-carousel {
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
}
.swiper-carousel .swiper-slide,
.swiper-carousel swiper-slide {
  position: relative;
}
