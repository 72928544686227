.ourClient {
  background: #fff;
  padding-top: 174px;
  .head {
    h3 {
      text-align: center;
      color: var(--black);
      font-family: "Montserrat";
      font-size: 7.8vw;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      span {
        color: var(--primary);
      }
    }
  }
  .clientReview-slider {
    padding: 0px 0px 100px 150px;
    margin-top: 90px;
    position: relative;
    .slick-slider {
      .slick-arrow {
        background: transparent;
        border: 1px solid transparent;
        border-radius: 18px;
      }
      .slick-prev {
        top: 110%;
        left: 90%;
        &::before {
          content: url(/assets/icons/leftarrow.svg);
          opacity: 1 !important;
        }
      }
      .slick-next {
        right: 5%;
        top: 110%;
        &::before {
          content: url(/assets/icons/rightarrow.svg);
          opacity: 1 !important;
        }
      }
    }

    .for-layer {
      max-width: 670px;
      border-radius: 20px;
      border: 2.048px solid #cbcbcb;
      min-height: 360px;
      background: #fff;
      padding: 30px;
      position: relative;
      overflow: hidden;
      display: inline-block;
      align-items: center;
      .slide-1 {
        position: relative;
        z-index: 2;

        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          position: relative;
          z-index: 4;
          img {
            position: relative;
            z-index: 2;
            transition: all 0.4s linear;
          }
          &::after {
            content: " ";
            position: absolute;
            width: 62px;
            height: 62px;
            border: 2px solid var(--primary);
            left: 7px;
            border-radius: 35px;
            transition: all 0.4s linear;
          }
        }
        .bottom {
          display: flex;
          align-items: end;
          flex-direction: column;
          row-gap: 4px;
          padding-top: 14px;
          h4 {
            color: #4d4d4d;
            text-align: justify;
            font-family: "Roboto";
            font-size: 25px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .small-para {
            font-size: 15px;
            font-family: "Roboto";
            color: var(--gray);
          }
        }
        .large-para {
          transition: all 0.5s linear;
          text-align: justify;
          color: var(--gray);
        }
      }

      .layer {
        transition: all 0.5s linear;
        position: absolute;
        width: 200px;
        height: 200px;
        right: -8%;
        top: 50%;
        transform: translateY(-50%);
        background-color: var(--primary);
        border-radius: 50%;
        opacity: 0;
        z-index: 1;
      }
      &:hover {
        .layer {
          opacity: 1;
          transform: scale(9);
        }
        .slide-1 {
          .top {
            &::after {
              border: 2px solid #fff;
              transition: all 0.4s linear;
            }
            .commas {
              filter: brightness(0.6);
            }
          }
          .bottom {
            h4 {
              color: var(--white);
            }
            .small-para {
              color: var(--white);
            }
          }
          p {
            color: var(--white);
          }
        }

        .hover-hide {
          display: none;
        }
        .hover-show {
          display: block !important;
        }
      }
    }
  }
  .slick-slide {
    float: right;
  }
}

@media screen and (max-width: 1780px) {
  .ourClient {
    .clientReview-slider {
      .for-layer {
        max-width: 600px;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .ourClient {
    padding: 90px 0px 100px 31px;
    .clientReview-slider {
      .slick-track {
        gap: 40px;
        display: flex;
      }
      .slick-slide {
        display: flex !important;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .ourClient {
    h2 span {
      background: #000;
      color: #fff;
      padding-left: 70px;
      padding-right: 20px;
    }
    .clientReview-slider {
      padding: 0px 50px 100px 10px;
      .for-layer {
        padding: 20px 30px;
        min-height: 370px;
      }
      .slick-slider {
        .slick-prev {
          left: 88%;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .ourClient {
    padding: 25px 20px;
    .head {
      h3 {
        font-size: 22px;
      }
    }
    h2 {
      font-size: 18px;
      span {
        padding: 0 30px;
      }
    }
    .clientReview-slider {
      margin-top: 10px;
      padding: 0px 10px 20px 10px;
      .slick-track {
        gap: 10px;
      }
      .for-layer {
        padding: 20px;
        min-height: 320px;
        .slide-1 {
          position: relative;
          z-index: 2;

          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            position: relative;
            z-index: 4;
            .comma {
              width: 30px;
              height: 30px;
            }
            img {
              width: 60px;
              height: 60px;
            }
            &::after {
              width: 60px;
              height: 60px;
              left: 5px;
            }
          }
          .bottom {
            padding-top: 10px;
            h4 {
              font-size: 16px;
            }
          }
          .large-para {
            font-size: 15px;
            text-align: left;
          }
        }
      }
      .slick-slider {
        .slick-next {
          right: 40%;
          top: 105%;
        }
        .slick-prev {
          left: 30%;
          top: 105%;
        }
      }
    }
  }
}
