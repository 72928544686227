.btn-primary {
  border-radius: 13.451px;
  background: var(
    linear-gradient(90deg, #3385ff 0%, #20bdff 50%, #3385ff 100%)
  );
  box-shadow: 0px 1.922px 6.726px 0px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: 24.02px;
  font-weight: 500;
  color: var(--white);
}
