.servicecard-sec {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  .servicecard-top {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 415px;

    .top-layer {
      transition: all 0.6s linear;
      z-index: 3;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 2px;
      padding: 47px;
      h3 {
        text-transform: uppercase;
        font-size: 2.8vw;
        font-family: "Roboto";
      }
      .large-para {
        max-width: 70%;
      }
    }
    .cardtop-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .servicecard-bottom {
    padding: 1.9vw 3vw 5px 1.5vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;
    h1 {
      line-height: 75%;
    }
    .servicecard-circle {
      width: 66px;
      height: 66px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid;
      border-radius: 100%;
      transition: all 0.5s linear;
      svg {
        transform: rotate(45deg);
        width: 26px;
        height: 26px;
      }
      &:hover {
        border-radius: 30%;
      }
    }
    .bottom-left {
      display: flex;
      align-items: center;
      column-gap: 12px;
      h6 {
        transition: all 0.6s linear;
        font-family: "Roboto";
        font-size: 2vw;
        // font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 221.531% */
        text-transform: uppercase;
        opacity: 0;
        letter-spacing: unset;
      }
      h1 {
        font-size: 9.2vw;
      }
    }
  }
  &:hover {
    .servicecard-top {
      .top-layer {
        transform: translateY(-100%);
      }
    }
    .servicecard-bottom {
      .bottom-left {
        h6 {
          transition: all 0.6s linear;
          opacity: 1;
        }
      }
    }
  }
}
.servicecard-sec:nth-child(1),
.servicecard-sec:nth-child(4),
.servicecard-sec:nth-child(5),
.servicecard-sec:nth-child(8) {
  .servicecard-top {
    color: var(--white);
    .top-layer {
      background: var(--lightblack);
    }
  }
  .servicecard-bottom {
    background: var(--lightblack);
    color: var(--white);
  }
}
.servicecard-sec:nth-child(2),
.servicecard-sec:nth-child(3),
.servicecard-sec:nth-child(6),
.servicecard-sec:nth-child(7) {
  .servicecard-top {
    color: var(--black);
    .top-layer {
      background: var(--white);
    }
  }
  .servicecard-bottom {
    background: var(--white);
    color: var(--black);
  }
}

@media screen and (max-width: 1500px) {
  .servicecard-sec {
    .servicecard-top {
      .top-layer {
        padding: 30px;
        .large-para {
          font-size: 18px;
        }
      }
    }
    .servicecard-bottom {
      padding: 20px 40px 5px 15px;
      .servicecard-circle {
        width: 40px;
        height: 40px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .bottom-left {
        column-gap: 10px;

        h1 {
          font-size: 8vw;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .servicecard-sec {
    .servicecard-top {
      .top-layer {
        padding: 30px;
      }
    }
    .servicecard-bottom {
      padding: 20px 40px 5px 15px;
      .servicecard-circle {
        width: 40px;
        height: 40px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .bottom-left {
        column-gap: 10px;

        h1 {
          font-size: 8vw;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .servicecard-sec {
    .servicecard-top {
      .top-layer {
        h3 {
          font-size: 2.4vw;
        }
        .large-para {
          font-size: 15px;
          max-width: 100%;
        }
      }
    }
    .servicecard-bottom {
      padding: 15px 25px 5px 10px;
      column-gap: 10px;
      h1 {
        line-height: 75%;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .servicecard-sec {
    .servicecard-top {
      .top-layer {
        padding: 20px;
        h3 {
          font-size: 5vw;
        }
      }
    }
    .servicecard-bottom {
      padding: 15px 15px 5px 15px;
      column-gap: 10px;
      .servicecard-circle {
        width: 30px;
        height: 30px;
        svg {
          width: 15px;
          height: 15px;
        }
      }
      .bottom-left {
        column-gap: 10px;
        h6 {
          font-size: 4vw;
        }
        h1 {
          font-size: 12vw;
        }
      }
    }
  }
  .servicecard-sec:nth-child(1),
  .servicecard-sec:nth-child(3),
  .servicecard-sec:nth-child(5),
  .servicecard-sec:nth-child(7) {
    .servicecard-top {
      color: var(--white);
      .top-layer {
        background: var(--lightblack);
      }
    }
    .servicecard-bottom {
      background: var(--lightblack);
      color: var(--white);
    }
  }
  .servicecard-sec:nth-child(2),
  .servicecard-sec:nth-child(4),
  .servicecard-sec:nth-child(6),
  .servicecard-sec:nth-child(8) {
    .servicecard-top {
      color: var(--black);
      .top-layer {
        background: var(--white);
      }
    }
    .servicecard-bottom {
      background: var(--white);
      color: var(--black);
    }
  }
}
