.servicepagecards-sec {
  .heading {
    padding: 200px 80px 100px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1237px;
    margin: 0px auto;
    width: 100%;
    row-gap: 35px;
    .detail-para {
      text-align: center;
      color: var(--black);
    }
  }
  .cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1500px) {
  .servicepagecards-sec {
    .heading {
      padding: 100px 80px;
      row-gap: 30px;
      .detail-para {
        font-size: 18px;
      }
    }
    .cards {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media screen and (max-width: 1024px) {
  .servicepagecards-sec {
    .heading {
      padding: 50px 40px;
      row-gap: 20px;
      .detail-para {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .servicepagecards-sec {
    .heading {
      padding: 40px 10px;
      row-gap: 20px;
      .detail-para {
        font-size: 15px;
      }
    }
    .cards {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
