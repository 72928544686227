.language-switcher {
    position: relative;
    top:1px
  }
  .dropo{
    position: absolute;
    top: 5px;
  }
  
  .dropdowns-toggle {
    background: transparent;
    border: none;
    cursor: pointer;
  }
  
  .flag-icon {
    width: 24px; /* Adjust as needed */
    height: auto;
  }
  
  .dropdown-menu {
    display: block; /* Ensure it's not set to 'none' by default */
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    z-index: 1000; /* Ensure it appears above other content */
    padding: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  