.blogs-sec {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  .inner {
    padding: 0px 100px 200px 100px;
    .blog-cards {
      max-width: 1321px;
      margin: 0px auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 44px;
      padding-top: 90px;
      .blog-card {
        position: relative;
        box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.09);
        padding: 174px 31px 15px 31px;
        border-radius: 12px;
        background: var(--white);
        transition: all 0.4s linear;
        cursor: pointer;
        .img-div {
          padding: 0px 31px;
          width: 100%;
          position: absolute;
          left: 50%;
          right: 50%;
          transform: translate(-50%);
          top: -25%;
          .blog-img {
            transition: all 0.4s ease-in;
            border-radius: 12.138px;
            max-width: 349px;
            max-height: 220px;
            height: 100%;
            width: 100%;
          }
          .date {
            transition: all 0.4s ease-in;
            border: 4px solid var(--primary);

            background: var(--primary);
            width: 106px;
            height: 106px;
            border-radius: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: -20%;
            right: 15%;
            .large-para {
              transition: all 0.4s ease-in;

              color: #fff;
              font-feature-settings: "clig" off, "liga" off;
              font-family: "Montserrat";
              font-size: 25px;
              font-style: normal;
              font-weight: 400;
              line-height: 28.058px; /* 112.234% */
              &:first-child {
                font-weight: 700;
              }
            }
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          padding-bottom: 32px;
          border-bottom: 1px solid #cbcbcb;
          h6 {
            color: var(--black);
            font-family: "Montserrat";
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 112.234%; /* 112.234% */
            letter-spacing: unset;
          }
          .small-para {
            color: var(--black);
          }
        }
        .details {
          padding-top: 18px;
          display: flex;
          align-items: center;
          column-gap: 10px;
          justify-content: space-between;
          label {
            color: #3e4343;
            font-family: "Montserrat";
            font-size: 12.927px;
            font-style: normal;
            font-weight: 400;
            line-height: 187.056%; /* 187.056% */
            span {
              color: var(--black);
              font-weight: 600;
            }
          }
          .center {
            height: 39px;
            width: 1px;
            background: #cbcbcb;
          }
          .right {
            display: flex;
            align-items: center;
            column-gap: 3px;
          }
        }
        &:hover {
          .img-div {
            .blog-img {
              transform: scale(1.01);
              box-shadow: 0px 0px 17px 4px rgba(111, 0, 255, 0.31);
            }
            .date {
              background: var(--white);
              border: 4px solid var(--primary);
              .large-para {
                color: var(--primary);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1520px) {
  .blogs-sec {
    padding-top: 70px;
    .inner {
      padding: 0px 80px 100px 80px;
      .blog-cards {
        column-gap: 30px;
        padding-top: 80px;
        .blog-card {
          padding: 13vw 20px 15px 20px;
          .img-div {
            padding: 0px 20px;

            .date {
              width: 80px;
              height: 80px;

              .large-para {
                font-size: 20px;
                line-height: 120%;
              }
            }
          }
          .content {
            row-gap: 15px;
            padding-bottom: 20px;
            h6 {
              font-size: 22px;
            }
          }
          .details {
            padding-top: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .blogs-sec {
    padding-top: 50px;
    .inner {
      padding: 0px 60px 60px 60px;
      .blog-cards {
        column-gap: 20px;
        padding-top: 80px;
        .blog-card {
          padding: 11.5vw 20px 15px 20px;
          .img-div {
            padding: 0px 20px;
            .date {
              width: 80px;
              height: 80px;

              .large-para {
                font-size: 18px;
              }
            }
          }
          .content {
            row-gap: 10px;
            padding-bottom: 20px;
            h6 {
              font-size: 20px;
            }
          }
          .details {
            padding-top: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .blogs-sec {
    padding-top: 40px;
    .inner {
      padding: 0px 40px 40px 40px;
      .blog-cards {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 120px;
        padding-top: 80px;
        .blog-card {
          margin: 0px auto;
          max-width: 600px;
          padding: 200px 31px 15px 31px;
          .img-div {
            top: -25%;
            .blog-img {
              max-width: unset;
              max-height: 250px;
              object-fit: cover;
            }
            .date {
              right: 10%;
              bottom: -18%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .blogs-sec {
    padding-top: 25px;
    .inner {
      padding: 0px 20px 40px 20px;
      .blog-cards {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 110px;
        padding-top: 80px;
        .blog-card {
          margin: 0px auto;
          max-width: 600px;
          padding: 150px 31px 15px 31px;
          .img-div {
            top: -25%;
            .blog-img {
              max-width: unset;
              max-height: 200px;
              object-fit: cover;
            }
            .date {
              height: 60px;
              width: 60px;
              right: 10%;
              bottom: -12%;
              .large-para {
                font-size: 15px;
              }
            }
          }
          .content {
            padding-bottom: 10px;
            h6 {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .blogs-sec {
    .inner {
      .blog-cards {
        .blog-card {
          padding: 36vw 31px 15px 31px;
          .img-div {
            top: -25%;
            .blog-img {
              max-width: unset;
              max-height: 200px;
              object-fit: cover;
            }

            .date {
              height: 60px;
              width: 60px;
              right: 10%;
              bottom: -12%;
              .large-para {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}
