.letsconnect-sec {
  background: url(/assets/img/letsconnect.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .inner {
    padding: 86px 100px;
    max-width: 1715px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 100px;
    .left {
      display: flex;
      column-gap: 41px;
      align-items: center;
      img {
        width: 120px;
        height: 96px;
        object-fit: cover;
      }
      h5 {
        color: var(--white);
        font-style: normal;
        font-weight: 700;
      }
    }
    .right {
      background: var(--white);
      padding: 20px 66px;
      border-radius: 42.5px;
      cursor: pointer;
      h3 {
        color: var(--primary);
        text-align: center;
        font-family: Montserrat;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .letsconnect-sec {
    .inner {
      padding: 60px 80px;
      column-gap: 80px;
      .left {
        display: flex;
        column-gap: 30px;
        img {
          width: 80px;
          height: 70px;
          object-fit: contain;
        }
        h5 {
          font-size: 30px;
        }
      }
      .right {
        padding: 15px 40px;
        h3 {
          font-size: 22px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .letsconnect-sec {
    .inner {
      padding: 40px;
      column-gap: 30px;
      .left {
        column-gap: 10px;
        img {
          width: 60px;
          height: 54px;
        }
        h5 {
          font-size: 20px;
        }
      }
      .right {
        padding: 10px 20px;
        h3 {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 630px) {
  .letsconnect-sec {
    .inner {
      padding: 40px 20px;
      flex-direction: column;
      justify-content: center;
      row-gap: 10px;
      .left {
        column-gap: 10px;
        flex-direction: column;
        row-gap: 10px;
        img {
          width: 50px;
          height: 40px;
          object-fit: contain;
        }
        h5 {
          font-size: 18px;
          text-align: center;
          width: fit-content;
        }
      }
      .right {
        background: var(--white);
        padding: 8px 20px;
        border-radius: 42.5px;
        h3 {
          font-size: 16px;
        }
      }
    }
  }
}
