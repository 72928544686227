.happy-client {
  padding: 10px 50px 100px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    min-width: 1614px;
    padding: 18px 14px;
    border-radius: 11px;
    background: linear-gradient(90deg, #6f00ff 0%, #25007f 100%);
    display: flex;
    align-items: center;
    gap: 100px;
    .left {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h2 {
        color: #9194fc;
        font-family: Montserrat;
        font-size: 60px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: uppercase;
      }
      h3 {
        color: #fff;
        font-family: Montserrat;
        font-size: 50px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: uppercase;
      }
    }
    .right {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 11px;
      background: linear-gradient(90deg, #6f00ff 0%, #25007f 100%);
      padding: 22px 32px;
      position: relative;
      z-index: 1;
      h6 {
        color: #fff;
        font-family: Montserrat;
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: uppercase;
        letter-spacing: 0;
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .happy-client {
    .content {
      min-width: 1314px;
      gap: 50px;
      .left {
        h2 {
          font-size: 50px;
        }
        h3 {
          font-size: 40px;
        }
      }
      .right {
        h6 {
          font-size: 28px;
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .happy-client {
    .content {
      min-width: 100%;
      gap: 50px;
      .left {
        h2 {
          font-size: 40px;
        }
        h3 {
          font-size: 30px;
        }
      }
      .right {
        h6 {
          font-size: 25px;
        }
      }
    }
  }
}
@media screen and (max-width: 1250px) {
  .happy-client {
    .content {
      min-width: 100%;
      gap: 50px;
      .left {
        h2 {
          font-size: 30px;
        }
        h3 {
          font-size: 20px;
        }
      }
      .right {
        h6 {
          font-size: 19px;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .happy-client {
    padding: 50px 30px;
    .content {
      min-width: 100%;
      gap: 50px;
      .left {
        h2 {
          font-size: 25px;
        }
        h3 {
          font-size: 15px;
        }
      }
      .right {
        h6 {
          font-size: 15px;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .happy-client {
    padding: 0px 20px;
    .content {
      flex-direction: column;
      min-width: 100%;
      gap: 10px;
      .left {
        width: 100%;
        h2 {
          font-size: 22px;
        }
        h3 {
          font-size: 18px;
        }
      }
      .right {
        width: 100%;
        padding: 0;
        h6 {
          font-size: 14px;
          text-align: center;
          font-weight: 700;
        }
      }
    }
  }
}
